<template>
    <div class="text-center py-5">
        validating...
        <div class="loader-image">
            <img src="@/assets/logo-2.png" alt="GoMart">
        </div>
    </div>
</template>

<script>
export default {
    props: ['token'],

    created() {
        if(this.$route.query.token) {
            localStorage.token = this.$route.query.token
        }
        axios.post(this.GLOBAL.API_BASE_URL + "customer-details", {
                token: localStorage.token
            })
            .then(response => {
                if(response.data.status == 'OK') {
                    // window.location.replace("/main-menu");
                    axios.defaults.headers.common['Authorization'] = 'Bearer '+ localStorage.token;
                    this.$router.push({ name:'main-menu' })
                } else {
                    Swal.fire({
                        text: 'Session expired',
                        timer: 5000,
                        onClose: () => {
                            window.ReactNativeWebView.postMessage("WINDOW_CLOSED")
                        }
                    })
                }
            })
            .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
            .finally(() => this.$set(this.state, 'loading', false))
    },
}
</script>

<style lang="scss" scoped>
    .loader-image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;

        img {
            max-width: 50vw;
            margin: auto;
        }
    }
</style>
