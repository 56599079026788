<template>
    <div class="gomart">
        <secondary-header title="GoMart" backRoute="default">
            <template v-slot:menu>
                <div class="main-menu text-only">
                    <ul class="nav nav-pills nav-fill container">
                        <li class="nav-item">
                            <a class="nav-item font-weight-bold">
                                <div class="text-dark">
                                    <span> {{ lang('My Cart') }}</span>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </template>
            <template v-slot:right>
                <div class="home-button">
                    <router-link :to="{name:'user-bar'}" class="nav-item">
                        <font-awesome-icon icon="bars" size="lg"></font-awesome-icon>
                    </router-link>
                </div>
            </template>
        </secondary-header>

        <main>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <div v-for="(carts, branch_ref_no) in cart_by_branches" class="remove-x-margin">
                            <div class="py-1 px-3 border bg-yellow">
                                <div class="custom-control custom-radio center large py-1 font-weight-bold">
                                    <input type="radio" :id="branch_ref_no" name="address_id" :value="branch_ref_no" class="custom-control-input" v-model="branch_selection">
                                    <label class="custom-control-label" :for="branch_ref_no">{{ carts[0].product.branch_name }}</label>
                                </div>
                            </div>
                            <div style="overflow:auto;">
                                <table class="product-list" width="100%">
                                    <template v-for="cart in carts">
                                        <tr>
                                            <td rowspan="2" class="left">
                                                <div class="ref-no small">
                                                    {{ cart.product.product_reference_no }}
                                                </div>
                                                <div class="image">
                                                    <img :src="cart.product.product_image_thumbnail_url[0]" alt="" @click.prevent="editCart(cart)">
                                                </div>
                                            </td>
                                            <td class="right right" style="vertical-align: top;">
                                                <div class="product-name">
                                                    {{ cart.product.product_name }}
                                                </div>
                                                <div class="details">
                                                    {{ lang('Availability') }}: <span v-if="cart.product.total_available > 0">{{ cart.product.total_available }}</span> <span v-else class="text-warning">{{ lang('Sold Out') }}</span> <br>
                                                </div>
                                                <div class="details">
                                                    <table style="position: relative;">
                                                        <template v-for="variant in cart.product.variants" v-if="cart.variants[variant.id] && cart.variants[variant.id].quantity">
                                                            <tr style="transform: scale(1);">
                                                                <td class="pr-1">{{ variant.name }}</td>
                                                                <td style="white-space:nowrap;">{{ money(variant.rate) }}</td>
                                                                <td style="white-space:nowrap;">{{ ' x '+ cart.variants[variant.id].quantity }}</td>
                                                                <td style="white-space:nowrap;">{{ ' = '+ money(variant.rate * cart.variants[variant.id].quantity) }}</td>
                                                                <div>
                                                                    <span v-if="variant.current_total <= 0" class="sold-out">
                                                                        <div class="">
                                                                            {{ lang('sold out') }}
                                                                        </div>
                                                                    </span>
                                                                    <span v-else-if="variant.current_total < cart.variants[variant.id].quantity" class="sold-out">
                                                                        <div class="">
                                                                            {{ lang('Only') }} {{ variant.current_total }} item available
                                                                        </div>
                                                                    </span>
                                                                </div>
                                                            </tr>
                                                            <tr>
                                                                <td v-if="variant.max_purchase && variant.current_max_purchase < cart.variants[variant.id].quantity" colspan="4" class="text-danger">
                                                                    Sorry, you have reached the purchase limit of {{ variant.max_purchase }}
                                                                </td>
                                                            </tr>
                                                        </template>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="right-last" style="vertical-align: bottom;">
                                                <div class="cart-action large">
                                                    <div class="custom-control custom-checkbox large">
                                                        <input type="checkbox" class="custom-control-input" :id="cart.id" :value="cart" v-model="selected_cart" :disabled="(selected_branch_ref_no != '' && selected_branch_ref_no != cart.product.branch_ref_no) || !cart.can_checkout">
                                                        <label class="custom-control-label" :for="cart.id"></label>
                                                    </div>
                                                    <div class="">
                                                        <a href="#" @click.prevent="editCart(cart)"><font-awesome-icon :icon="['far','edit']" size="lg"></font-awesome-icon></a>
                                                    </div>
                                                    <div class="">
                                                        <a href="#" @click.prevent="removeCart(cart)"><font-awesome-icon :icon="['fas','trash']" size="lg" class="text-dark"></font-awesome-icon></a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </table>
                            </div>
                        </div>
                    </div>

                    <float-bottom>
                        <div class="">
                            <router-link :to="{name: 'product.checkout', params: checkout_params }" class="btn btn-primary" :class="{disabled : selected_cart.length <= 0}">
                                <div class="tex-center">
                                    {{ lang('Check Out') }} {{ lang('Total') }}: {{ money(total_amount) }}
                                </div>
                            </router-link>
                        </div>
                    </float-bottom>

                </div>
            </div>
        </main>

        <loader v-if="state.loading"></loader>
        <main-footer></main-footer>
    </div>
</template>

<script>
export default {
    data() {
        return {
            cart_by_branches: [],
            selected_cart: [],
            branch_selection: '',

            selected_branch_ref_no: '',
        }
    },

    watch: {
        branch_selection: function(newVal) {
            this.selected_cart = []
            if(this.cart_by_branches[newVal]) {
                this.cart_by_branches[newVal].forEach((cart) => {
                    if(cart.can_checkout) {
                        this.selected_cart.push(cart)
                    }
                })
            }
        },

        selected_cart: function(newVal) {
            this.selected_branch_ref_no = ''
            newVal.forEach((cart, i) => {
                this.selected_branch_ref_no = cart.product.branch_ref_no
            });
            if(this.selected_branch_ref_no != this.branch_selection) {
                this.branch_selection = ''
            }
        }
    },

    computed: {
        total_amount: function() {
            var total = 0
            for (var key in this.selected_cart) {
                if (this.selected_cart.hasOwnProperty(key)) {
                    // loop selected variant
                    for(var variant_id in this.selected_cart[key].variants) {
                        if(this.selected_cart[key].variants.hasOwnProperty(variant_id)) {
                            var variant = this.selected_cart[key].product.variants.find(variant => variant.id == variant_id)
                            if(variant) {
                                total += variant.rate_raw * this.selected_cart[key].variants[variant_id].quantity
                            }
                        }
                    }
                }
            }
            return total
        },

        checkout_params: function() {
            var products = [];
            var quantity = {};
            this.selected_cart.forEach((cart) => {
                products.push(cart.product)
                Object.assign(quantity, cart.variants)
            });

            return {
                products: products,
                quantity: quantity
            }
        }
    },

    methods: {
        loadCustomerCart() {
            this.$set(this.state, 'loading', true)
            axios.get(this.GLOBAL.API_BASE_URL +"customer-cart")
                .then(response => {
                    this.cart_by_branches = response.data
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading', false))
        },

        editCart(cart) {
            this.$router.push({ name:'product.add-to-cart', params: {fudc: {}, product: cart.product, prev_quantity: cart.variants} })
        },

        removeCart(cart) {
            Swal.fire({
                text: this.lang('Are you sure?'),
                showCancelButton: true,
                confirmButtonText: this.lang('Confirm'),
            })
            .then(result => {
                if(result.isConfirmed) {
                    axios.delete(this.GLOBAL.API_BASE_URL +"customer-cart/"+ cart.id)
                        .then(response => {
                            this.loadCustomerCart()
                            this.unCheckCart(cart)
                        })
                        .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                        .finally(() => this.$set(this.state, 'loading', false))
                }
            })
        },

        unCheckCart(cart) {
            this.selected_cart = this.selected_cart.filter((el) => el.id != cart.id)
        }
    },

    created() {
        this.loadCustomerCart()
    },
}
</script>

<style lang="scss" scoped>
.cart-action {
    right: 0.5rem;
    bottom: 0.5rem;

    > * {
        margin: 0 0.3rem;
        float: right;
    }
}
.custom-radio {
    &.center {
        label {
            display: flex;
            align-items: center;

            &::before,
            &::after {
                top: unset
            }
        }
    }

    &.address {
        .custom-control-input:checked ~ label::before {
            border-color: #d4c18a;
            background-color: #dccc9a;
        }
    }
}

.custom-checkbox {
    .custom-control-label::before,
    .custom-control-label::after {
        width: 1.25rem;
        height: 1.25rem;
    }
}

.sold-out {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    background: #ff0000c4;
    font-weight: bold;
    color: white;
    border-radius: 0.3rem;
    display: flex;
    align-items: center;

    > * {
        width: 100%;
    }
}
</style>
