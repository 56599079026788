<template>
    <div class="gomart single-header">
        <secondary-header :title="lang('GoMart')" backRoute="default"></secondary-header>

        <main>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <div class="header text-center mt-2">
                            <div class="title">
                                {{ receipt.branch_name }}
                            </div>
                            <div class="px-3" style="white-space: pre-line;">
                                {{ receipt.branch_address }} <br>
                                {{ receipt.branch_phone }}
                            </div>

                            <div class="title py-1">
                                <div class="">
                                    {{ lang('OFFICIAL RECEIPT') }}
                                </div>
                                <img v-if="receipt.receipt_no_qr" :src="'data:image/svg+xml;base64,'+ receipt.receipt_no_qr" alt="" class="qr-code">
                            </div>
                        </div>

                        <table class="my-2" width="100%">
                            <tr>
                                <td class="font-weight-bold">{{ lang('Receipt No') }}</td>
                                <td> : {{ receipt.receipt_no }}</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">{{ lang('Date') }}</td>
                                <td> : {{ dateUi(receipt.pay_at) }}</td>
                            </tr>
                        </table>

                        <table class="my-2" width="100%">
                            <tr>
                                <td class="font-weight-bold">{{ lang('Order No') }}</td>
                                <td> : {{ receipt.order_no }}</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">{{ lang('Name') }}</td>
                                <td> : {{ receipt.customer_name }}</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold">{{ lang('Mobile No') }}</td>
                                <td> : {{ receipt.customer_phone }}</td>
                            </tr>
                        </table>
                        <b>{{ lang('Payment Method') }}</b> : {{ receipt.payment_method_ui }}

                        <table class="mt-3" width="100%">
                            <tr class="border-top border-bottom">
                                <th class="px-2 py-1">{{ lang('Description') }}</th>
                                <th class="px-2 text-right">{{ lang('Amount') }}</th>
                            </tr>
                            <tr v-for="product in receipt.products" class="border-bottom">
                                <td colspan="2" class="px-2 py-1 va-top" style="height: 1%;">
                                    <b>{{ product.main_product.name }}</b>
                                    <table width="100%">
                                        <tr v-for="variant in receipt.product_variants[product.id]" class="va-top" style="white-space: nowrap;">
                                            <td width="1%">{{ variant.variant_name }}</td>
                                            <td width="1%" class="pl-2">{{ money(variant.variant_rate) }}</td>
                                            <td> x {{ variant.quantity }}</td>
                                            <td class="text-right va-top">{{ money(variant.amount) }}</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr v-for="product in receipt.pwp_products" class="border-bottom">
                                <td colspan="2" class="px-2 py-1 va-top" style="height: 1%;">
                                    <b>{{ product.main_product.name }}</b>
                                    <table width="100%">
                                        <tr v-for="variant in receipt.pwp_prices[product.id]" class="va-top" style="white-space: nowrap;">
                                            <td width="1%">{{ lang('PWP') }} - {{ variant.variant_name }}</td>
                                            <td width="1%" class="pl-2">{{ money(variant.variant_rate) }}</td>
                                            <td> x {{ variant.quantity }}</td>
                                            <td class="text-right va-top">{{ money(variant.amount) }}</td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                        <table class="ml-auto mr-2">
                            <tr>
                                <td class="pr-2">Subtotal</td>
                                <td class="text-right">RM</td>
                                <td class="text-right">{{ money(receipt.sub_total, false) }}</td>
                            </tr>
                            <tr>
                                <td class="pr-2">Delivery Fee</td>
                                <td class="text-right">RM</td>
                                <td class="text-right">{{ money(receipt.delivery_fee, false) }}</td>
                            </tr>
                            <tr>
                                <td class="font-weight-bold pr-2">Total</td>
                                <td class="text-right font-weight-bold">RM</td>
                                <td class="text-right font-weight-bold">{{ money(receipt.total_amount, false) }}</td>
                            </tr>
                        </table>

                        <div class="pt-3">
                            {{ lang('This is computer generated receipt no signature required.') }}
                        </div>

                        <float-bottom>
                            <div>
                                <a class="btn btn-secondary text-center" data-toggle="modal" data-target="#email-me-receipt">
                                    <div class="text-center large">
                                        {{ lang('Email Me') }}
                                    </div>
                                </a>
                            </div>
                        </float-bottom>

                        <div class="modal fade" id="email-me-receipt" tabindex="-1" role="dialog" aria-labelledby="email-me-receipt" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered" role="document">
                                <div class="modal-content">
                                    <div class="modal-body">
                                        <form @submit.prevent="submitEmail()" autocomplete="off">
                                            <div class="form-group">
                                                <label for="email_address">{{ lang('Email address') }}</label>
                                                <input type="email" class="form-control" id="email_address" v-model="email_address" required>
                                            </div>

                                            <div class="mt-3 text-right">
                                                <button type="button" class="btn btn-danger mr-1" data-dismiss="modal">{{ lang('Cancel') }}</button>
                                                <button type="submit" class="btn btn-primary" >{{ lang('Submit') }}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <loader v-if="state.loading"></loader>
        <main-footer></main-footer>
    </div>
</template>

<script>
export default {
    props: ['prev_order', 'order_id'],

    data() {
        return {
            receipt: {},

            email_address: '',

            status: {
                DRAFT: 'Draft',
                PENDING_ORDER: 'Pending Order',
                PENDING_PAYMENT: 'Pending Payment',
                IN_PROGRESS: 'In Progress',
                VOID: 'Void',
                CANCELLED: 'Cancelled',
                READY: 'Ready',
                DELIVERED: 'Delivered',
                COMPLETED: 'Completed',
            },

            delivery_methods: {
                SELF_COLLECT: 'Self Collect',
                DELIVERY: 'Delivery',
            }
        }
    },

    methods: {
        loadOrder() {
            this.$set(this.state, 'loading', true)
            axios.get(this.GLOBAL.API_BASE_URL +"receipt/"+ this.order_id)
                .then(response => {
                    this.receipt = response.data
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading', false))
        },

        submitEmail() {
            if(this.email_address) {
                fireLoader(this.lang('Sending email...'))
                axios.post(this.GLOBAL.API_BASE_URL +"receipt/"+ this.order_id +"/email-me", {email_address: this.email_address})
                    .then(response => {
                        $('#email-me-receipt').modal('hide')
                        Swal.fire({
                            html: this.lang('The Receipt sent to your email') + '<br>' + this.email_address,
                            confirmButtonText: this.lang('Close')
                        })
                        this.email_address = ''
                    })
                    .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
            }
        }
    },

    created() {
        this.order = this.prev_order ? this.prev_order : {}
        this.loadOrder()
    }
}
</script>

<style lang="scss" scoped>
    img {
        &.qr-code {
            width: 9rem;
            max-width: 100%;
        }
    }

    .title {
        font-weight: bold;
        font-size: 1.1rem;
    }

    body {
        font-size: 0.8rem;
    }

    @media print {
        body {
            font-size: 1rem;
        }

        #print {
            display: none;
        }
    }
</style>
