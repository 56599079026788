<template>
    <div class="modal fade" id="ratingModal" tabindex="-1" role="dialog" aria-labelledby="ratingModalTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <table width="100%">
                        <tr>
                            <td></td>
                            <td colspan="2">{{ lang('Weak') }}</td>
                            <td colspan="3" class="text-right">{{ lang('Good') }}</td>
                        </tr>
                        <tr v-for="(rating, rating_slug) in ratings">
                            <td class="font-weight-bold" :class="{'text-danger': failed.includes(rating_slug)}">{{ rating.name }}</td>
                            <td class="text-center py-1 text-primary" :class="{'text-danger': failed.includes(rating_slug)}"><font-awesome-icon :icon="[(rating.rate >= 1 ? 'fas' : 'far'),'star']" size="2x" @click="changeRating(rating_slug, 1)"></font-awesome-icon></td>
                            <td class="text-center py-1 text-primary" :class="{'text-danger': failed.includes(rating_slug)}"><font-awesome-icon :icon="[(rating.rate >= 2 ? 'fas' : 'far'),'star']" size="2x" @click="changeRating(rating_slug, 2)"></font-awesome-icon></td>
                            <td class="text-center py-1 text-primary" :class="{'text-danger': failed.includes(rating_slug)}"><font-awesome-icon :icon="[(rating.rate >= 3 ? 'fas' : 'far'),'star']" size="2x" @click="changeRating(rating_slug, 3)"></font-awesome-icon></td>
                            <td class="text-center py-1 text-primary" :class="{'text-danger': failed.includes(rating_slug)}"><font-awesome-icon :icon="[(rating.rate >= 4 ? 'fas' : 'far'),'star']" size="2x" @click="changeRating(rating_slug, 4)"></font-awesome-icon></td>
                            <td class="text-center py-1 text-primary" :class="{'text-danger': failed.includes(rating_slug)}"><font-awesome-icon :icon="[(rating.rate == 5 ? 'fas' : 'far'),'star']" size="2x" @click="changeRating(rating_slug, 5)"></font-awesome-icon></td>
                        </tr>
                    </table>

                     <div class="mt-3 text-right">
                         <button type="button" class="btn btn-danger mr-1" data-dismiss="modal">{{ lang('Cancel') }}</button>
                         <button type="button" class="btn btn-primary" @click="submitRating()">{{ lang('Confirm') }}</button>
                     </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['order'],

    data() {
        return {
            ratings: {
                time: {
                    name: this.lang('Time'),
                    rate: 0,
                },
                price: {
                    name: this.lang('Price'),
                    rate: 0,
                },
                quality: {
                    name: this.lang('Quality'),
                    rate: 0,
                },
                response: {
                    name: this.lang('Response'),
                    rate: 0,
                },
            },

            failed: []
        }
    },

    methods: {
        changeRating(slug, rate) {
            this.ratings[slug].rate = rate

            for( var i = 0; i < this.failed.length; i++){
                if ( this.failed[i] == slug) {
                    this.failed.splice(i, 1);
                }
            }
        },

        submitRating() {
            var available = true
            for(const slug in this.ratings) {
                available = this.ratings[slug].rate > 0 && available
                if(this.ratings[slug].rate == 0) {
                    this.failed.push(slug)
                }
            }
            if(available) {
                Swal.fire({
                    text: this.lang('Submit this rating.'),
                    showDenyButton: true,
                    showCancelButton: true,
                    confirmButtonText: 'Confirm',
                }).then((result) => {
                    if (result.isConfirmed) {
                        fireLoader()
                        axios.post(this.GLOBAL.API_BASE_URL +"rating/order/"+ this.order.id, this.ratings)
                            .then(response => {
                                Swal.close()
                                $('#ratingModal').modal('hide')
                                this.$router.push({ name: 'history'})
                            })
                            .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                    }
                })
            }
        }
    }
}
</script>
