<template>
    <div class="">
        <slot></slot>

        <div class="modal fade" id="modal-new-address" tabindex="-1" role="dialog" aria-labelledby="modal-new-addressTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-body">
                        <form @submit.prevent="submitAddress" @keydown="removeError($event.target.name)" @change="removeError($event.target.name)">
                            <div class="form-group">
                                <label for="recipient_name">{{ lang('Recipient Name') }} <span class="text-danger">*</span></label>
                                <input type="text" name="recipient_name" class="form-control" id="recipient_name" v-model="inputs.recipient_name">
                            </div>
                            <div class="form-group">
                                <label for="phone_no">{{ lang('Contact No') }} <span class="text-danger">*</span></label>
                                <input type="text" name="phone_no" class="form-control" id="phone_no" v-model="inputs.phone_no">
                            </div>
                            <div class="form-group">
                                <label for="address_text">{{ lang('Address') }} <span class="text-danger">*</span></label>
                                <textarea type="text" name="address_text" class="form-control" id="address_text" v-model="inputs.address_text" rows="4" />
                            </div>
                            <div class="form-group">
                                <label for="postcode">{{ lang('Postcode') }} <span class="text-danger">*</span></label>
                                <input type="text" name="postcode" class="form-control" id="postcode" v-model="inputs.postcode">
                            </div>
                            <div class="form-group">
                                <label for="state_id">{{ lang('State') }} <span class="text-danger">*</span></label>
                                <select class="form-control" id="state_id" name="state_id" v-model="inputs.state_id">
                                    <option value=""></option>
                                    <option v-for="state in states" :value="state.id">{{ state.name }}</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="city_id">{{ lang('City') }} <span class="text-danger">*</span></label>
                                <select class="form-control" id="city_id" name="city_id" v-model="inputs.city_id">
                                    <option value=""></option>
                                    <option v-for="city in cities" v-if="city.state_id == inputs.state_id" :value="city.id">{{ city.name }}</option>
                                </select>
                            </div>

                            <div class="form-group row">
                                <div class="col-6 pr-1">
                                    <label for="latitude">{{ lang('Latitude') }}</label>
                                    <input type="text" name="latitude" class="form-control" id="latitude" v-model="inputs.latitude">
                                </div>
                                <div class="col-6 pl-1">
                                    <label for="longitude">{{ lang('Longitude') }}</label>
                                    <input type="text" name="longitude" class="form-control" id="longitude" v-model="inputs.longitude">
                                </div>
                            </div>
                            <div class="form-group">
                                <div id="map" style="width:100%;height:200px;"></div>
                            </div>
                            <div class="text-right">
                                <button type="button" class="btn btn-danger mr-2" data-dismiss="modal">{{ lang('Cancel') }}</button>
                                <button type="submit" class="btn btn-primary">{{ lang('Save') }}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader"

export default {
    props: ['default_value'],

    data() {
        return {
            states: {},
            cities: {},
            inputs: {},
            initiateData: false,
        }
    },

    watch: {
        'inputs.state_id': function() {
            if(!this.initiateData) {
                this.inputs.city_id = ''
            }
            this.initiateData = false
        }
    },

    methods: {
        submitAddress() {
            if(this.inputs.id) {
                this.update()
            } else {
                this.create()
            }
        },

        create() {
            this.$set(this.state, 'loading', true)
            axios.post(this.GLOBAL.API_BASE_URL +"customer-address", this.inputs)
                .then(response => {
                    this.$emit('address-created')
                    $('#modal-new-address').modal('hide');
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading', false))
        },

        update() {
            this.$set(this.state, 'loading', true)
            axios.put(this.GLOBAL.API_BASE_URL +"customer-address/"+ this.inputs.id, this.inputs)
                .then(response => {
                    this.$emit('address-created')
                    $('#modal-new-address').modal('hide');
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading', false))
        },

        loadGoogleMap() {
            var _this = this

            const loader = new Loader({
                apiKey: this.GLOBAL.GOOGLE_MAP_API_KEY,
                version: "weekly",
                libraries: ["places"]
            });

            // initiate google map
            loader.load().then(() => {

                var mapCenter = new google.maps.LatLng(5.479158, 117.03246)

                var map = new google.maps.Map(document.getElementById("map"), {
                    center: mapCenter,
                    zoom: 7,
                });
                var marker = new google.maps.Marker({position: mapCenter});

                // center if has coordinate
                if(_this.inputs.latitude && _this.inputs.longitude) {
                    var default_pos = {
                        lat: parseFloat(_this.inputs.latitude),
                        lng: parseFloat(_this.inputs.longitude),
                    }
                    map.setCenter(default_pos);
                    map.setZoom(15);
                    marker.setMap(null);
                    marker = new google.maps.Marker({
                        position: default_pos,
                        map: map
                    });
                }

                var infoWindow = new google.maps.InfoWindow();
                const locationButton = document.createElement("button");
                locationButton.textContent = "Pan to Current Location";
                locationButton.classList.add("custom-map-control-button");
                map.controls[google.maps.ControlPosition.TOP_CENTER].push(locationButton);
                locationButton.addEventListener("click", (e) => {
                    e.preventDefault()
                    // Try HTML5 geolocation.
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition(
                            (position) => {
                                const pos = {
                                    lat: position.coords.latitude,
                                    lng: position.coords.longitude,
                                };
                                map.setCenter(pos);
                                map.setZoom(15);
                                marker.setMap(null);
                                marker = new google.maps.Marker({
                                    position: pos,
                                    map: map
                                });
                                _this.$set(_this.inputs, 'latitude', pos.lat)
                                _this.$set(_this.inputs, 'longitude', pos.lng)
                            },
                            () => {
                                handleLocationError(true, infoWindow, map.getCenter());
                            }
                        );
                    } else {
                        // Browser doesn't support Geolocation
                        handleLocationError(false, infoWindow, map.getCenter());
                    }
                });


                google.maps.event.addListener(map, 'click', function(event) {
                    var location = event.latLng
                    map.setCenter(location);
                    map.setZoom(15);
                    marker.setMap(null);
                    marker = new google.maps.Marker({
                        position: location,
                        map: map
                    });
                    _this.$set(_this.inputs, 'latitude', location.lat().toFixed(7))
                    _this.$set(_this.inputs, 'longitude', location.lng().toFixed(7))
                });
            });
        },

        loadStateCity() {
            this.$set(this.state, 'loading', true)
            axios.get(this.GLOBAL.API_BASE_URL +"selection/state-city")
                .then(response => {
                    this.states = response.data.states
                    this.cities = response.data.cities
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading', false))
        }
    },

    created() {
        this.loadStateCity()
    },

    mounted() {
        $('#modal-new-address').on('hidden.bs.modal', (e) => {
            this.inputs = {
                state_id: '',
                city_id: '',
            }
            removeError()
        })

        $('#modal-new-address').on('show.bs.modal', (e) => {
            this.$emit('address-modal-closed')
            if(this.default_value) {
                this.initiateData = true
                this.inputs = { ...this.default_value }
            }
            this.loadGoogleMap()
        })
    }
}
</script>
