<template>
    <div class="text-center py-5">
        {{ lang('Processing...') }}
        <loader v-if="state.loading"></loader>
    </div>
</template>

<script>
export default {
    props: ['products', 'quantity', 'delivery_details'],

    methods: {
        createOrder() {
            this.$set(this.state, 'loading', true)
            axios.post(this.GLOBAL.API_BASE_URL + "create-order", {
                    products: this.products,
                    quantity: this.quantity,
                    delivery_details: this.delivery_details,
                })
                .then(response => {
                    this.$router.replace({name:'product.checkout.pwp', params:{order_id:response.data.order.id}})
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading', false))
        }
    },

    created() {
        this.createOrder()
    },

    mounted() {
        $('body').addClass('remove-header-menu')
    },

    destroyed () {
        $('body').removeClass('remove-header-menu')
    },

    beforeRouteEnter (to, from, next) {
        if(to.params.products == undefined) {
            next({name: 'main-menu', replace: true})
        }
        else {
            next()
        }
    },
}
</script>
