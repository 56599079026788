<template>
    <div class="gomart pwp-checkout single-header">
        <loader v-if="state.loading"></loader>
        <template v-else>
            <secondary-header :title="lang('Purchase-With-Purchase')" backRoute="default"></secondary-header>

            <main>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-md-10">
                            <div class="banner remove-x-margin mb-1">
                                <div class="owl-carousel owl-theme">
                                    <div v-for="banner in pwp_details.pwp_banners" class="image">
                                        <div :style="'background-image: url('+banner+');'" class="banner-image"></div>
                                    </div>
                                </div>

                                <div class="countdown">
                                    <div class="title">
                                        {{ lang('Current Event Ends In') }}
                                    </div>
                                    <div class="timer">
                                        <div v-if="timer.days">
                                            <span v-if="timer.days[0] > 0">{{ timer.days[0] }}</span>
                                            <span>{{ timer.days[1] }}</span>
                                            <span>{{ timer.days[1] }}</span>
                                            <div class="">
                                                {{ lang('days') }}
                                            </div>
                                        </div>
                                        <div v-if="timer.hours">
                                            <span>{{ timer.hours[0] }}</span>
                                            <span>{{ timer.hours[1] }}</span>
                                            <div class="">
                                                {{ lang('hours') }}
                                            </div>
                                        </div>
                                        <div v-if="timer.minutes">
                                            <span>{{ timer.minutes[0] }}</span>
                                            <span>{{ timer.minutes[1] }}</span>
                                            <div class="">
                                                {{ lang('mins') }}
                                            </div>
                                        </div>
                                        <div v-if="timer.seconds">
                                            <span>{{ timer.seconds[0] }}</span>
                                            <span>{{ timer.seconds[1] }}</span>
                                            <div class="">
                                                {{ lang('secs') }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="item-list row p-1 pb-3">
                                <div v-for="item in pwp_details.pwp_items" class="col-6 item">
                                    <div class="inner">
                                        <div class="image" @click="showItem(item)">
                                            <img :src="item.product_image_thumbnail_url" alt="">
                                        </div>
                                        <div class="details">
                                            <div class="name">
                                                {{ item.product_name }}
                                            </div>
                                            <div class="variant">
                                                <span v-if="item.total_available > 0" class="float-right text-success">{{ lang('In Stock') }}</span>
                                                <span v-else class="float-right text-danger">{{ lang('Out of Stock') }}</span>
                                                {{ item.name }}
                                            </div>
                                            <div class="row">
                                                <div class="col-6 pr-0" style="flex: 0 0 55%; max-width: 55%;">
                                                    <div class="original-price">
                                                        {{ lang('Was') }} {{ money(item.original_price) }}
                                                    </div>
                                                    <div class="current-price">
                                                        {{ money(item.price) }}
                                                    </div>
                                                </div>
                                                <div class="col-6 pl-0" style="flex: 0 0 45%; max-width: 45%;">
                                                    <div class="input-group">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text" @click="deductQuantity(item)" :class="{'disabled': quantity_pwp[item.id] <= 0 || (cant_add_variant && !(quantity_pwp[item.id] > 0))}">-</span>
                                                        </div>
                                                        <input type="text" class="form-control text-center" :class="{'disabled': (item.total_available <= 0 || (cant_add_variant && !(quantity_pwp[item.id] > 0)))}" :value="quantity_pwp[item.id]" readonly>
                                                        <div class="input-group-append">
                                                            <span class="input-group-text" @click="addQuantity(item)" :class="{'disabled': (item.max_purchase > 0 && quantity_pwp[item.id] >= item.current_max_purchase) || quantity_pwp[item.id] >= item.total_available || (cant_add_variant && !(quantity_pwp[item.id] > 0))}">+</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <main-footer></main-footer>

            <float-bottom>
                <div>
                    <router-link :to="{name: 'my-order.pay', params: {order_id: order_id} }" class="btn btn-warning text-white">
                        <div class="text-center">
                            {{ lang('No, Thanks.') }}
                        </div>
                    </router-link>
                    <router-link :to="{name: 'my-order.pay', params: {order_id: order_id, quantity_pwp: quantity_pwp} }" class="btn btn-primary" :class="{disabled : sub_total_pwp <= 0}">
                        <div class="text-center">
                            {{ lang('Pay Now -') }} {{ money(total_amount_with_pwp) }}
                        </div>
                    </router-link>
                </div>
            </float-bottom>
        </template>
    </div>
</template>

<script>
export default {
    props: ['order_id'],

    data() {
        return {
            pwp_details: {},
            quantity_pwp: {},

            quantity_pwp_count: 0,

            countdown_seconds: 0,
            timer: {},
            interval_cd: '',
        }
    },

    watch: {
        quantity_pwp: {
            deep: true,
            handler(newVal) {
                var not_empty_quantity = 0
                for (var key in newVal) {
                    if (newVal.hasOwnProperty(key) && newVal[key] > 0) {
                        not_empty_quantity++
                    }
                }
                this.quantity_pwp_count = not_empty_quantity
            }
        },
    },

    computed: {
        sub_total_pwp: function() {
            var total = 0
            for (var key in this.quantity_pwp) {
                if (this.quantity_pwp.hasOwnProperty(key)) {
                    var variant = this.pwp_details.pwp_items.find(variant => variant.id == key)
                    if(variant) {
                        total += this.quantity_pwp[key] * variant.price
                    }
                }
            }
            return total
        },

        total_amount_with_pwp: function() {
            return (this.pwp_details.order ? this.pwp_details.order.total_amount : 0) + this.sub_total_pwp
        },

        cant_add_variant: function() {
            return this.quantity_pwp_count >= this.pwp_details.pwp_offer.max_variant
        }
    },

    methods: {
        countdown() {
            this.countdown_seconds = moment(this.pwp_details.pwp_offer.period_to).endOf('day').diff(moment(), 'seconds');
            this.interval_cd = setInterval(() => {
                this.countdown_seconds = this.countdown_seconds - 1;
                var timer_dhms = this.secondsToDhms(this.countdown_seconds)
                this.$set(this.timer, 'days', timer_dhms[0])
                this.$set(this.timer, 'hours', timer_dhms[1])
                this.$set(this.timer, 'minutes', timer_dhms[2])
                this.$set(this.timer, 'seconds', timer_dhms[3])

                if (this.countdown_seconds <= 0) {
                    clearInterval(this.interval_cd);
                    this.$router.replace({name:'my-order.pay', params:{order_id:this.order_id}})
                }
            }, 1000)
        },

        secondsToDhms(seconds) {
            seconds = Number(seconds);
            var d = Math.floor(seconds / (3600*24));
            var h = Math.floor(seconds % (3600*24) / 3600);
            var m = Math.floor(seconds % 3600 / 60);
            var s = Math.floor(seconds % 60);

            var dDisplay = ("00" + d).slice (-3);
            var hDisplay = ("0" + h).slice (-2);
            var mDisplay = ("0" + m).slice (-2);
            var sDisplay = ("0" + s).slice (-2);
            return [dDisplay, hDisplay, mDisplay, sDisplay];
        },

        getPwpDetails() {
            this.$set(this.state, 'loading', true)
            if(this.$attrs.pwp_details) {
                this.pwp_details = this.$attrs.pwp_details
                if(this.pwp_details.pwp_offer) {
                    this.countdown()
                    this.initiateOwlCarousel()
                    this.$set(this.state, 'loading', false)
                } else {
                    this.$router.replace({name:'my-order.pay', params:{order_id:this.order_id}})
                }
            } else {
                axios.post(this.GLOBAL.API_BASE_URL + "pwp/items", {
                        order_id: this.order_id,
                    })
                    .then(response => {
                        this.pwp_details = response.data
                        if(this.pwp_details.pwp_offer) {
                            this.countdown()
                            this.initiateOwlCarousel()
                        } else {
                            this.$router.replace({name:'my-order.pay', params:{order_id:this.order_id}})
                        }
                    })
                    .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                    .finally(() => this.$set(this.state, 'loading', false))
            }
        },

        initiateOwlCarousel() {
            setTimeout(() => {
                $('.owl-carousel').owlCarousel({
                    margin:10,
                    nav:false,
                    autoplay:true,
                    autoplayTimeout:5000,
                    autoplayHoverPause:true,
                    loop:true,
                    responsive:{
                        0:{
                            items:1
                        },
                    }
                });
                $('.owl-carousel').owlCarousel('refresh');
            }, 1500)
        },

        deductQuantity(variant) {
            this.$set(this.quantity_pwp, variant.id, (this.quantity_pwp[variant.id] || 0) - 1)
            if(this.quantity_pwp[variant.id] < 0) {
                this.quantity_pwp[variant.id] = 0
            }
            if(variant.max_purchase > 0 && this.quantity_pwp[variant.id] > variant.current_max_purchase) {
                this.quantity_pwp[variant.id] = variant.max_purchase
            }
            if(this.quantity_pwp[variant.id] > variant.total_available) {
                this.quantity_pwp[variant.id] = variant.total_available
                if(this.quantity_pwp[variant.id] < 0) {
                    this.quantity_pwp[variant.id] = 0
                }
            }
        },

        addQuantity(variant) {
            if(!this.cant_add_variant || this.quantity_pwp[variant.id] > 0) {
                this.$set(this.quantity_pwp, variant.id, (this.quantity_pwp[variant.id] || 0) + 1)
                if(variant.max_purchase > 0 && this.quantity_pwp[variant.id] > variant.current_max_purchase) {
                    this.quantity_pwp[variant.id] = variant.current_max_purchase
                }
                if(this.quantity_pwp[variant.id] > variant.total_available) {
                    this.quantity_pwp[variant.id] = variant.total_available
                    if(this.quantity_pwp[variant.id] < 0) {
                        this.quantity_pwp[variant.id] = 0
                    }
                }
            }
        },

        showItem(item) {
            this.$router.push({name:'product.checkout.pwp.show', params: {item: item, pwp_details: this.pwp_details, quantity_pwp: this.quantity_pwp, order_id: this.order_id}})
        }
    },

    created() {
        this.getPwpDetails()

        if(this.$attrs.quantity_pwp) {
            this.quantity_pwp = this.$attrs.quantity_pwp
        }
    },

    beforeRouteEnter (to, from, next) {
        if(to.params.order_id == undefined) {
            next({name: 'main-menu', replace: true})
        }
        else {
            next()
        }
    },

    beforeRouteLeave(to, from, next) {
        clearInterval(this.interval_cd);
        to.params['order_id'] = this.order_id
        next()
    }
}
</script>

<style lang="scss">
    .pwp-checkout {
        background-color: #f9f9f9;

        .banner {
            height: 50vw;
            max-height: 20rem;
            position: relative;
            box-shadow: 0 2px 5px 0px #a2a2a2;
        }

        .owl-carousel {
            .owl-item .banner-image {
                height: 50vw;
                max-height: 20rem;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                position: relative;
            }

            .owl-dots {
                margin-top: 5px !important;

                .owl-dot {
                    span {
                        height: 7px;
                        width: 7px;
                    }

                    &:focus {
                        outline: 0 !important;
                    }
                }
            }
        }

        .countdown {
            text-align: center;
            font-size: .9rem;
            min-height: 4.6rem;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 1rem;
            z-index: 1;

            .title {
                padding: 0.1rem;
                text-shadow: 0 0 4px white;
            }

            .timer {
                display: flex;
                align-items: center;
                justify-content: center;

                > div {
                    margin: 0.2rem 0.15rem;

                    > div {
                        padding: 0.2rem;
                        font-size: 0.65rem;
                        text-shadow: 0 0 4px white;
                    }
                }

                span {
                    background-color: #ffffff;
                    padding: 0.2rem 0.3rem;
                    margin: 0.1rem;
                    box-shadow: 2px 2px 5px 0px #a7a7a7;
                    border-radius: 0.2rem;
                }
            }
        }

        .item-list {
            .item {
                padding: 0.4rem;

                .inner {
                    background-color: #ffffff;
                    display: flex;
                    flex-wrap: wrap;
                    border-radius: 0.3rem;
                    height: 100%;

                    .image {
                        width: 100%;
                        align-items: center;
                        display: flex;

                        img {
                            max-height: 100vw;
                            max-width: 100%;
                            margin: auto;
                        }
                    }

                    .details {
                        margin-top: auto;
                        width: 100%;
                        background-color: #f2f2f2;
                        padding: 0.3rem;
                        border-radius: 0 0 0.3rem 0.3rem;

                        .name {
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-size: 0.85rem;
                        }

                        .variant {
                            font-size: 0.7rem;
                            color: #787373;
                        }

                        .original-price {
                            font-size: 0.7rem;
                            color: #787373;
                        }

                        .current-price {
                            font-size: 0.8rem;
                            color: #ed7f35;
                        }

                        .input-group {
                            input {
                                font-size: 0.7rem;
                                padding: 0.1rem 0;
                                height: auto;
                                background-color: #ffffff;

                                &.disabled {
                                    background-color: #c8c8c8;
                                }
                            }
                            .input-group-text {
                                padding: 0.5rem 0.4rem;
                                line-height: 0.5;
                                background-color: #ffffff;

                                &.disabled {
                                    background-color: #a6a6a6;
                                    color: #ffffff !important;
                                }
                            }
                        }
                    }
                }
            }
        }

        .float-bottom {
            box-shadow: 0 -2px 4px 1px #c3c3c3;

            > div,
            > div > div {
                height: 4rem;
            }
            a {
                margin: 1.2rem 0.4rem 0.5rem !important;
                border-radius: 0.5rem !important;
                box-shadow: 1px 2px 4px 1px #c3c3c3;

                &:not(:last-child) {
                    margin-right: 0.2rem !important;
                }
                &:not(:first-child) {
                    margin-left: 0.2rem !important;
                }
            }
        }

        .image-slider {
            .owl-dots {
                display: none;
            }
        }

        .text-grey {
            color: #787373;
        }
    }
</style>
