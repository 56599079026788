import Vue from 'vue'
import VueRouter from 'vue-router'
import Auth from '../Auth.vue'
import Empty from '../Empty.vue'
import MainMenu from '../views/MainMenu.vue'

import ProductIndex from '../views/product/Index.vue'
import ProductMenu from '../views/product/Menu.vue'
import ProductBranch from '../views/product/Branch.vue'
import ProductBranchMenuList from '../views/product/BranchMenuList.vue'
import ProductAddToCart from '../views/product/AddToCart.vue'
import ProductCheckout from '../views/product/Checkout.vue'
import ProductCheckoutPwp from '../views/pwp/CheckoutPwp.vue'
import ProductCheckoutPwpShow from '../views/pwp/CheckoutPwpShow.vue'
import ProductCheckoutProceed from '../views/product/CheckoutProceed.vue'

import PaymentStatusGopay from '../views/PaymentStatusGopay.vue'


import UserBar from '../views/UserBar.vue'

import MyOrder from '../views/MyOrder.vue'
import ViewOrder from '../views/ViewOrder.vue'
import PayOrder from '../views/PayOrder.vue'
import ViewReceipt from '../views/ViewReceipt.vue'

import History from '../views/History.vue'

import MyAddress from '../views/MyAddress.vue'

import MyCart from '../views/MyCart.vue'

import MyFavourite from '../views/MyFavourite.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'auth',
        component: Auth,
        props: true
    },
    {
        path: '/main-menu',
        name: 'main-menu',
        component: MainMenu,
        meta: { requiresAuth: true }
    },
    {
        path: '/product',
        component: Empty,
        children: [
            {
                path: '/',
                component: ProductIndex,
                props: true,
                children: [
                    {
                        path: 'menu',
                        name: 'product.menu',
                        component: ProductMenu,
                        props: true
                    },
                    {
                        path: 'branch',
                        name: 'product.branch',
                        component: ProductBranch,
                        props: true
                    },
                    {
                        path: 'branch/menu-list',
                        name: 'product.branch.menu-list',
                        component: ProductBranchMenuList,
                        props: true
                    },
                    {
                        path: 'add-to-cart',
                        name: 'product.add-to-cart',
                        component: ProductAddToCart,
                        props: true
                    },
                ]
            },
            {
                path: 'checkout',
                name: 'product.checkout',
                component: ProductCheckout,
                props: true
            },
            {
                path: 'checkout-pwp',
                name: 'product.checkout.pwp',
                component: ProductCheckoutPwp,
                props: true
            },
            {
                path: 'checkout-pwp-show',
                name: 'product.checkout.pwp.show',
                component: ProductCheckoutPwpShow,
                props: true
            },
            {
                path: 'checkout-proceed',
                name: 'product.checkout.proceed',
                component: ProductCheckoutProceed,
                props: true
            },
        ]
    },
    {
        path: '/payment-status/gopay/:transaction_id',
        name: 'bills.payment-status-gopay',
        component: PaymentStatusGopay,
        props: true,
        meta: { requiresAuth: true }
    },

    {
        path: '/my-order',
        name: 'my-order',
        component: MyOrder,
        meta: { requiresAuth: true }
    },
    {
        path: '/my-order/:order_id',
        name: 'my-order.view',
        component: ViewOrder,
        props: true,
        meta: { requiresAuth: true }
    },
    {
        path: '/my-order-pay',
        name: 'my-order.pay',
        component: PayOrder,
        props: true,
        meta: { requiresAuth: true }
    },
    {
        path: '/my-receipt/:order_id',
        name: 'my-receipt.view',
        component: ViewReceipt,
        props: true,
        meta: { requiresAuth: true }
    },

    {
        path: '/history',
        name: 'history',
        component: History,
        meta: { requiresAuth: true }
    },

    {
        path: '/user-bar',
        name: 'user-bar',
        component: UserBar,
        meta: { requiresAuth: true }
    },
    {
        path: '/my-address',
        name: 'my-address',
        component: MyAddress,
        meta: { requiresAuth: true }
    },
    {
        path: '/my-cart',
        name: 'my-cart',
        component: MyCart,
        meta: { requiresAuth: true }
    },
    {
        path: '/my-favourite',
        name: 'my-favourite',
        component: MyFavourite,
        meta: { requiresAuth: true }
    },
    // {
    //     path: '/about',
    //     name: 'About',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    // }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
