<template>
    <div class="gomart">
        <secondary-header title="GoMart" backRoute="default">
            <template v-slot:menu>
                <div class="main-menu text-only">
                    <ul class="nav nav-pills nav-fill container">
                        <li class="nav-item">
                            <a class="nav-item font-weight-bold">
                                <div class="text-dark">
                                    <span> {{ lang('My History') }}</span>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </template>
            <template v-slot:right>
                <div class="home-button">
                    <router-link :to="{name:'user-bar'}" class="nav-item">
                        <font-awesome-icon icon="bars" size="lg"></font-awesome-icon>
                    </router-link>
                </div>
            </template>
        </secondary-header>

        <main>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <div class="remove-x-margin">
                            <table width="100%">
                                <tr v-for="order in orders.data">
                                    <td class="p-2 border-bottom" colspan="2">
                                        <b>{{ lang('Order No') }}</b>: {{ order.order_no }} <br>
                                        <span class="float-right font-weight-bold" :class="order.status" v-html="status[order.status]"></span>
                                        <b>{{ lang('Date') }}</b>: {{ order.order_at }} <br>

                                        <div class="pt-2">
                                            {{ lang('Merchant') }}: <b>{{ order.branch_name }}</b> <br>
                                            {{ lang('Total Menu') }}: <b>{{ order.product_count }}</b> <br>
                                            {{ lang('Total Item') }}: <b>{{ order.product_variant_count }}</b> <br>
                                            <b>{{ lang('Total Amount') }}: <span class="text-danger">{{ money(order.total_amount) }}</span></b>
                                            <router-link :to="{name: 'my-order.view', params:{order_id: order.id, prev_order: order}}" class="btn btn-sm btn-secondary view-details">{{ lang('View Details') }}</router-link>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="state.loading_next">
                                    <td class="p-3 text-center">{{ lang('loading') }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <loader v-if="state.loading"></loader>
        <main-footer></main-footer>
    </div>
</template>

<script>
export default {
    data() {
        return {
            orders: {
                data: [],
                meta: {}
            },
            status: {
                DRAFT: 'Draft',
                PENDING_ORDER: 'Pending Order',
                PENDING_PAYMENT: 'Pending Payment',
                IN_PROGRESS: 'In Progress',
                VOID: 'Void',
                CANCELLED: 'Cancelled',
                REJECTED: 'Rejected',
                READY: 'Ready',
                DELIVERED: 'Delivered',
                COMPLETED: 'Completed',
            }
        }
    },

    methods: {
        loadOrder() {
            this.$set(this.state, 'loading', true)
            axios.get(this.GLOBAL.API_BASE_URL +"order?status=COMPLETED")
                .then(response => {
                    this.orders.data = response.data.data
                    this.orders.next_page = response.data.links.next
                    this.orders.meta = response.data.meta
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading', false))
        },

        loadOrderNextPage() {
            let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight + 1 >= document.documentElement.offsetHeight

            if(this.orders.next_page && bottomOfWindow) {
                this.$set(this.state, 'loading_next', true)
                axios.get(this.GLOBAL.API_BASE_URL +"order?status=COMPLETED&page="+ this.orders.meta.next_page)
                    .then(response => {
                        this.orders.data.push.apply(this.orders.data, response.data.data)
                        this.orders.next_page = response.data.links.next
                        this.orders.meta = response.data.meta
                        this.loadOrderNextPage()
                    })
                    .catch(error=>defaultErrorManagement(error.response))
                    .finally(() => this.$set(this.state, 'loading_next', false))

                this.orders.next_page = ''
            }
        },
    },

    created() {
        this.loadOrder()
        window.addEventListener('scroll', this.loadOrderNextPage);
    },

    destroyed () {
        window.removeEventListener('scroll', this.loadOrderNextPage);
    },
}
</script>

<style lang="scss" scoped>
    table {
        td {
            vertical-align: top;
        }
    }

    .product-name {
        font-size: 1.1rem;
    }

    table {
        td {
            position: relative;
        }
    }

    .btn {
        &.view-details {
            position: absolute;
            right: 0.5rem;
            bottom: 0.5rem;
        }
    }
</style>
