<template>
    <div class="gomart">
        <secondary-header :title="order.status == 'COMPLETED' ? lang('My History') : lang('My Order')" backRoute="default">
            <template v-slot:menu>
                <div class="main-menu text-only">
                    <ul class="nav nav-pills nav-fill container">
                        <li class="nav-item">
                            <a class="nav-item font-weight-bold">
                                <div class="text-dark">
                                    <span> {{ lang('View Details') }}</span>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </template>
        </secondary-header>

        <main>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <div class="remove-x-margin">
                            <div class="p-2 border-bottom">
                                <b>{{ lang('Order No') }}</b>: {{ order.order_no }} <br>
                                <span class="float-right font-weight-bold order-status" :class="order.status" v-html="status[order.status]"></span>
                                <b>{{ lang('Date') }}</b>: {{ order.order_at }} <br>
                                <b>{{ lang('Merchant') }}</b>: {{ order.branch_name }} <br>
                                <b>{{ lang('Customer Name') }}</b>: {{ order.customer_name }} <br>
                                <!-- <b>{{ lang('Remark') }}</b>: {{ order.remark }} <br> -->
                                <b>{{ lang('Receipt No') }}</b>: {{ order.receipt_no }} <br>
                            </div>

                            <table class="product-list" width="100%">
                                <template v-for="product in order.products">
                                    <tr>
                                        <td rowspan="2" class="left">
                                            <div class="ref-no small">
                                                {{ product.code }}
                                            </div>
                                            <div class="image">
                                                <img :src="product.product_image_thumbnail_url[0]" alt="">
                                            </div>
                                        </td>
                                        <td class="right" style="vertical-align: top;">
                                            <div class="product-name">
                                                {{ product.name }}
                                            </div>
                                            <div class="details">
                                                <div v-for="variant in order.product_variants[product.id]">
                                                    <span class="mr-2" style="display:inline-block;">
                                                        {{ variant.variant_name }}
                                                    </span>
                                                    <span style="display:inline-block;">
                                                        <span class="ws-nowrap">{{ money(variant.variant_rate) }}</span> <span class="ws-nowrap">x {{ variant.quantity }}</span> <span class="ws-nowrap">= {{ money(variant.amount) }}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="right-last text-right p-2" style="vertical-align: bottom;">
                                            <!-- <div v-if="order.status == 'COMPLETED'" class="">
                                                <router-link :to="{ name:'product.add-to-cart', params: {fudc: {}, product: {id: product.id}, prev_quantity: prevQuantity(order.product_variants[product.id])} }" class="btn btn-warning text-center">
                                                    <div class="text-center large">
                                                        {{ lang('Buy Again') }}
                                                    </div>
                                                </router-link>
                                            </div> -->
                                        </td>
                                    </tr>
                                </template>
                                <template v-for="product in order.pwp_products">
                                    <tr>
                                        <td rowspan="2" class="left">
                                            <div class="ref-no small">
                                                {{ product.code }}
                                            </div>
                                            <div class="image">
                                                <img :src="product.product_image_thumbnail_url[0]" alt="">
                                            </div>
                                        </td>
                                        <td class="right" style="vertical-align: top; overflow: auto;">
                                            <div class="product-name">
                                                {{ product.name }}
                                            </div>
                                            <div class="details">
                                                <div v-for="variant in order.pwp_prices[product.id]" class="mb-1">
                                                    <span class="mr-2" style="display:inline-block;">
                                                        {{ variant.variant_name }}
                                                    </span>
                                                    <span style="display:inline-block;">
                                                        <span class="ws-nowrap">{{ money(variant.variant_rate) }}</span> <span class="ws-nowrap">x {{ variant.quantity }}</span> <span class="ws-nowrap">= {{ money(variant.amount) }}</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="pt-1" style="font-size: 0.9rem;">
                                                {{ lang('Purchase With Purchase') }}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="right-last text-right p-2" style="vertical-align: bottom;">
                                            <!-- <div v-if="order.status == 'COMPLETED'" class="">
                                                <router-link :to="{ name:'product.add-to-cart', params: {fudc: {}, product: {id: product.id}, prev_quantity: prevQuantity(order.product_variants[product.id])} }" class="btn btn-warning text-center">
                                                    <div class="text-center large">
                                                        {{ lang('Buy Again') }}
                                                    </div>
                                                </router-link>
                                            </div> -->
                                        </td>
                                    </tr>
                                </template>
                            </table>

                            <div class="p-2">
                                <table width="100%" class="large">
                                    <tr>
                                        <td>{{ 'Subtotal' }}</td>
                                        <td>:</td>
                                        <td class="text-right">{{ money(order.sub_total) }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ 'Delivery Fee' }}</td>
                                        <td>:</td>
                                        <td v-if="order.status == 'PENDING_ORDER'" class="text-right">RM ?</td>
                                        <td v-else class="text-right">{{ money(order.delivery_fee) }}</td>
                                    </tr>
                                    <tr>
                                        <td class="font-weight-bold">{{ 'Total' }}</td>
                                        <td>:</td>
                                        <td class="text-right font-weight-bold text-danger">{{ money(order.total_amount) }}</td>
                                    </tr>
                                </table>
                            </div>

                            <div class="p-2">
                                <table>
                                    <tr>
                                        <td><b>{{ lang('Payment Type') }}</b></td>
                                        <td>:</td>
                                        <td class="font-weight-bold">
                                            <span v-if="order.status == 'PENDING_ORDER' || order.status == 'PENDING_PAYMENT' || order.status == 'REJECTED' || order.status == 'CANCELLED'" class="PENDING_PAYMENT">{{ lang('Pending Payment') }}</span>
                                            <span v-else>{{ order.payment_method_ui }}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><b>{{ lang('Delivery Method') }}</b></td>
                                        <td>:</td>
                                        <td>{{ delivery_methods[order.delivery_method] }}</td>
                                    </tr>
                                </table>

                                <template v-if="order.delivery_method == 'DELIVERY'">
                                    {{ order.recipient_name }} <br>
                                    {{ order.customer_phone }} <br>
                                    <div class="px-1" style="white-space:pre-line;">
                                        {{ order.customer_address }}
                                    </div>
                                </template>
                                <template v-else-if="order.delivery_method == 'SELF_COLLECT'">
                                    {{ lang('Date') }}: {{ dateUi(order.self_collect_date) }} <br>
                                    {{ lang('Time') }}: {{ order.self_collect_time }} <br>
                                </template>

                                <div class="remark py-3">
                                    {{ lang('Remark') }} :
                                    <p class="border p-2" style="white-space:pre-line;">{{ order.remark }}</p>
                                </div>
                            </div>

                            <float-bottom>
                                <div v-if="order.receipt_no" class="">
                                    <router-link :to="{name:'my-receipt.view', params:{order_id:order.id}}" class="btn btn-secondary text-center">
                                        <div class="text-center large">
                                            {{ lang('Receipt') }}
                                        </div>
                                    </router-link>
                                </div>
                                <div v-if="order.status == 'PENDING_ORDER'" class="">
                                    <a class="btn btn-danger text-center" @click.prevent="cancelOrder()">
                                        <div class="text-center large">
                                            {{ lang('Cancel Order') }}
                                        </div>
                                    </a>
                                </div>
                                <div v-if="order.status == 'PENDING_PAYMENT'" class="">
                                    <a class="btn btn-danger text-center" @click.prevent="cancelOrder()">
                                        <div class="text-center large">
                                            {{ lang('Cancel Order') }}
                                        </div>
                                    </a>
                                    <router-link :to="{name:'product.checkout.pwp', params:{order_id:order.id}}" class="btn btn-secondary text-center">
                                        <div class="text-center large">
                                            {{ lang('Pay Now') }}
                                        </div>
                                    </router-link>
                                </div>
                                <div v-else-if="order.status == 'IN_PROGRESS' || order.status == 'READY'" class="">
                                    <a href="#" class="btn btn-success text-center" :class="{disabled: order.status == 'IN_PROGRESS'}" @click.prevent="orderReceived">
                                        <div class="text-center large">
                                            {{ lang('Received') }}
                                        </div>
                                    </a>
                                </div>
                                <div v-else-if="order.status == 'DELIVERED'" class="">
                                    <a class="btn btn-warning text-center" data-toggle="modal" data-target="#ratingModal">
                                        <div class="text-center large">
                                            {{ lang('Rate') }}
                                        </div>
                                    </a>
                                </div>
                            </float-bottom>

                            <!-- Modal rating -->
                            <Rating v-if="order.status == 'DELIVERED'" :order="order" />
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <loader v-if="state.loading"></loader>
        <main-footer></main-footer>
    </div>
</template>

<script>
import Rating from '@/components/Rating.vue'
export default {
    props: ['prev_order', 'order_id'],

    components: {Rating},

    data() {
        return {
            order: {},

            status: {
                DRAFT: 'Draft',
                PENDING_ORDER: 'Pending Order',
                PENDING_PAYMENT: 'Pending Payment',
                IN_PROGRESS: 'In Progress',
                VOID: 'Void',
                CANCELLED: 'Cancelled',
                REJECTED: 'Rejected',
                READY: 'Ready',
                DELIVERED: 'Delivered',
                COMPLETED: 'Completed',
            },

            delivery_methods: {
                SELF_COLLECT: 'Self Collect',
                DELIVERY: 'Delivery',
            }
        }
    },

    methods: {
        loadOrder() {
            this.$set(this.state, 'loading', true)
            axios.get(this.GLOBAL.API_BASE_URL +"order/"+ this.order_id)
                .then(response => {
                    this.order = response.data
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading', false))
        },

        orderReceived() {
            Swal.fire({
                text: this.lang('By clicking on the “Confirm” button, you hereby agree the Goods or Services received in good condition and release payment to the merchant.'),
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: 'Confirm',
            }).then((result) => {
                if (result.isConfirmed) {
                    fireLoader()
                    axios.get(this.GLOBAL.API_BASE_URL +"order/"+ this.order_id +"/set-received")
                        .then(response => {
                            this.loadOrder()
                            Swal.close()
                        })
                        .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                }
            })
        },

        cancelOrder() {
            fireLoader()
            axios.get(this.GLOBAL.API_BASE_URL +"order/"+ this.order_id +"/cancel")
                .then(response => {
                    this.loadOrder()
                    Swal.close()
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
        },

        prevQuantity(product_variants) {
            var keyed = _.mapKeys(product_variants, function(variant, key) {
                return variant.id
            })
            return _.mapValues(keyed, 'quantity')
        }
    },

    created() {
        this.order = this.prev_order ? this.prev_order : {}
        this.loadOrder()
    }
}
</script>

<style lang="scss" scoped>
    .order-status {
        font-size: 0.9rem;
    }

    .ws-nowrap {
        white-space: nowrap;
    }
</style>
