<template>
    <div class="">
        <table class="branch-list">
            <tr>
                <td rowspan="3" class="left">
                    <div class="image">
                        <img :src="branch.branch_logo_url" alt="">
                    </div>
                    <div class="small my-1">
                        {{ branch.branch_ref_no }}
                    </div>
                </td>
                <td class="right">
                    <div class="product-name">
                        {{ branch.branch_name }}
                    </div>
                    <div class="details">
                        {{ branch.branch_phone_no }} <br>
                        {{ branch.city }}, {{ branch.state }} <br>
                        <template v-if="!$parent.ignore_filter">{{ lang('FUDC') }}: {{ fudc.name }} <br> </template>
                        {{ branch.description }}
                    </div>
                </td>
            </tr>
            <tr>
                <td class="right-middle">
                    <table class="text-center rating" width="100%">
                        <tr>
                            <td class="text-warning">
                                {{ branch.total_trasaction }}
                            </td>
                            <td class="text-warning">
                                {{ branch.rating }}
                            </td>
                            <td class="text-warning">
                                {{ branch.chat_response_percent }}
                            </td>
                        </tr>
                        <tr>
                            <td class="small">
                                {{ lang('Transaction') }}
                            </td>
                            <td class="small">
                                {{ lang('Rating') }}
                            </td>
                            <td class="small">
                                {{ lang('Chat Response') }}
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr>
                <td class="right-last">
                    <div class="row no-gutters">
                        <div class="navigation col-12">
                            <div>
                                <a :href="branch.tnc_link ? ('https://drive.google.com/viewerng/viewer?embedded=true&url='+ branch.tnc_link) : ''" class="text-confirm">T&C</a> | <a :href="'https://maps.google.com/maps?daddr='+ branch.latitude +','+ branch.longitude" class="text-confirm">Location</a>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </table>
        <template v-for="product in $parent.branch_products.data">
            <table class="product-list" :key="product.id">
                <tr :key="product.id">
                    <td rowspan="2" class="left">
                        <div class="ref-no small">
                            {{ product.product_code }}
                        </div>
                        <div class="image">
                            <router-link :to="{ name:'product.add-to-cart', params: {product: product, product_branch: branch} }">
                                <img :src="product.product_image_thumbnail_url" alt="">
                            </router-link>
                        </div>
                        <div class="view small my-1">
                            <img src="@/assets/action_icon/VIEW.png" alt="" width="25"> {{ product.total_view }}
                        </div>
                    </td>
                    <td class="right">
                        <div class="product-name">
                            {{ product.product_name }}
                        </div>
                        <div class="details">
                            <span v-if="product.special_price_is_active_period" class="float-right font-weight-bold text-right">
                                <del class="text-disabled small">{{ product.price_variant }}/{{ product.unit_variant }}</del> <br>
                                <span class="text-danger">{{ product.special_price }}</span>/{{ product.unit_variant }}
                            </span>
                            <span v-else class="float-right font-weight-bold"><span class="text-danger">{{ product.price_variant }}</span>/{{ product.unit_variant }}</span>
                            {{ lang('Transacted') }}: {{ product.total_transacted }} <br>
                            {{ lang('Availability') }}: <span v-if="product.total_available > 0">{{ product.total_available }}</span> <span v-else class="text-warning">{{ lang('Sold Out') }}</span> <br>
                            {{ product.city }}, {{ product.state }} <br>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="right-last">
                        <div class="row no-gutters">
                            <div class="navigation col-7">
                                <div>
                                    <a :href="product.tnc_link" class="text-confirm">T&C</a> | <a :href="'https://maps.google.com/maps?daddr='+ product.latitude +','+ product.longitude" class="text-confirm">Location</a>
                                    <div class="mt-1 navigation-icons">
                                        <img src="@/assets/action_icon/CHAT.png" alt="" class="icon">
                                        <favourite-icon :product="product" />
                                        <img src="@/assets/action_icon/MERCHANT.png" alt="" class="icon">
                                        <img src="@/assets/action_icon/SHARE.png" alt="" class="icon">
                                        <img v-if="product.is_halal" src="@/assets/action_icon/HALAL.png" alt="" class="icon">
                                    </div>
                                </div>
                            </div>
                            <div class="col-5">
                                <!-- <button class="btn btn-secondary btn-sm btn-block wip disabled">{{ lang('Booking') }}</button> -->
                                <router-link :to="{ name:'product.add-to-cart', params: {product: product, product_branch: branch} }" class="btn btn-primary btn-sm btn-block text-white">{{ lang('Add to cart') }}</router-link>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
        </template>
        <table class="m-auto">
            <tr v-if="this.state.loading_next">
                <td colspan="2" class="text-center p-3">{{ lang('loading') }}</td>
            </tr>
            <tr v-if="!this.$parent.state.loading && this.$parent.products.meta.total == 0">
                <td colspan="2" class="text-center p-3">{{ lang('No Data') }}</td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    props: ['fudc', 'keyword', 'product_branch'],

    data() {
        return {
            branch: this.product_branch,
            products: {
                data: [],
                meta: {},
            }
        }
    },

    computed: {
        filters: function() {
            return {branch_ref_no: this.product_branch.branch_ref_no}
        }
    },

    methods: {
        loadBranch() {
            axios.post(this.GLOBAL.API_BASE_URL +"get-branch", {reference_no: this.branch.branch_ref_no})
                .then(response => {
                    this.branch = response.data
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
        },

        loadBranchMenuProduct() {
            this.$set(this.$parent.state, 'loading', true)
            axios.post(this.GLOBAL.API_BASE_URL +"product/menu", this.filters)
                .then(response => {
                    this.$parent.branch_products.data = response.data.data
                    this.$parent.branch_products.next_page = response.data.links.next
                    this.$parent.branch_products.meta = response.data.meta
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.$parent.state, 'loading', false))
        },

        loadBranchMenuProductNextPage() {
            let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight + 1 >= document.documentElement.offsetHeight

            if(this.$parent.branch_products.next_page && bottomOfWindow) {
                this.$set(this.state, 'loading_next', true)
                axios.post(this.GLOBAL.API_BASE_URL +"product/menu?page="+ this.$parent.branch_products.meta.next_page, this.filters)
                    .then(response => {
                        this.$parent.branch_products.data.push.apply(this.$parent.branch_products.data, response.data.data)
                        this.$parent.branch_products.next_page = response.data.links.next
                        this.$parent.branch_products.meta = response.data.meta
                        this.loadBranchMenuProductNextPage()
                    })
                    .catch(error=>defaultErrorManagement(error.response))
                    .finally(() => this.$set(this.state, 'loading_next', false))

                this.$parent.branch_products.next_page = ''
            }
        }
    },

    created() {
        if(this.$parent.branch_products.data.length <= 0) {
            this.loadBranchMenuProduct()
        }

        if(!this.branch.branch_name) {
            this.loadBranch()
        }

        window.addEventListener('scroll', this.loadBranchMenuProductNextPage);
    },

    mounted() {
        $('body').addClass('remove-header-menu')
        $('body').find('.gomart').addClass('bg-default')
    },

    destroyed () {
        $('body').removeClass('remove-header-menu')
        $('.gomart').removeClass('bg-default')

        window.removeEventListener('scroll', this.loadBranchMenuProductNextPage);
    },

    beforeRouteEnter (to, from, next) {
        if(to.params.product_branch == undefined) {
            next({name: 'main-menu', replace: true})
        }
        else {
            next()
        }
    },

    beforeRouteLeave(to, from, next) {
        this.$parent.branch_products.data = []
        to.params['fudc'] = this.fudc
        to.params['keyword'] = this.keyword
        next()
    }
}
</script>
