<template>
    <div class="gomart pwp-checkout single-header">
        <secondary-header :title="lang('Purchase-With-Purchase')" backRoute="default"></secondary-header>

        <main>
            <div class="image-slider">
                <div class="owl-carousel owl-theme">
                    <div v-for="image_url in item.product_image_regular_url" class="item">
                        <div :style="'background-image: url('+image_url+');'" class="product-image"></div>
                    </div>
                </div>
                <span v-if="item.product_image_regular_url.length > 0" id="counter" class="text-default"></span>
            </div>

            <div class="item-list">
                <div class="p-2 px-3">
                    <div class="product-name">
                        {{ item.product_name }}
                    </div>
                    <div class="details">
                        <div class="variant">
                            {{ item.name }}
                        </div>
                        <div class="total-sold text-right mb-3">
                            {{ item.total_transacted }} {{ lang('Sold') }}
                        </div>
                        <div class="row">
                            <div class="col-7 pr-0">
                                <div class="original-price">
                                    {{ lang('Was') }} {{ money(item.original_price) }}
                                </div>
                                <div class="current-price">
                                    {{ money(item.price) }}
                                </div>
                            </div>
                            <div class="col-5 pl-0">
                                <div class="">
                                    <span v-if="item.total_available > 0" class="float-right text-success">{{ lang('In Stock') }}</span>
                                    <span v-else class="float-right text-danger">{{ lang('Out of Stock') }}</span>
                                </div>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text" @click="deductQuantity(item)" :class="{'disabled': quantity_pwp_new[item.id] <= 0}">-</span>
                                    </div>
                                    <input type="text" class="form-control text-center" :class="{'disabled': item.total_available <= 0}" :value="quantity_pwp_new[item.id]" readonly>
                                    <div class="input-group-append">
                                        <span class="input-group-text" @click="addQuantity(item)" :class="{'disabled': (item.max_purchase > 0 && quantity_pwp_new[item.id] >= item.current_max_purchase) || quantity_pwp_new[item.id] >= item.total_available}">+</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="description pb-3">
                        {{ item.description }}
                    </div>
                </div>
            </div>
        </main>
        <main-footer></main-footer>

        <float-bottom>
            <div>
                <a href="#" @click.prevent="updateQuantity" class="btn btn-primary" :class="{disabled : (!quantity_pwp_new[item.id] || quantity_pwp_new[item.id] <= 0)}">
                    <div class="text-center">
                        {{ lang('Add') }}
                    </div>
                </a>
            </div>
        </float-bottom>
    </div>
</template>

<script>
export default {
    props: ['item', 'pwp_details', 'quantity_pwp', 'order_id'],

    data() {
        return {
            quantity_pwp_new: '',
            quantity_pwp_updated: false,
        }
    },

    methods: {

        deductQuantity(variant) {
            this.$set(this.quantity_pwp_new, variant.id, (this.quantity_pwp_new[variant.id] || 0) - 1)
            if(this.quantity_pwp_new[variant.id] < 0) {
                this.quantity_pwp_new[variant.id] = 0
            }
            if(variant.max_purchase > 0 && this.quantity_pwp_new[variant.id] > variant.current_max_purchase) {
                this.quantity_pwp_new[variant.id] = variant.max_purchase
            }
            if(this.quantity_pwp_new[variant.id] > variant.total_available) {
                this.quantity_pwp_new[variant.id] = variant.total_available
                if(this.quantity_pwp_new[variant.id] < 0) {
                    this.quantity_pwp_new[variant.id] = 0
                }
            }
        },

        addQuantity(variant) {
            this.$set(this.quantity_pwp_new, variant.id, (this.quantity_pwp_new[variant.id] || 0) + 1)
            if(variant.max_purchase > 0 && this.quantity_pwp_new[variant.id] > variant.current_max_purchase) {
                this.quantity_pwp_new[variant.id] = variant.current_max_purchase
            }
            if(this.quantity_pwp_new[variant.id] > variant.total_available) {
                this.quantity_pwp_new[variant.id] = variant.total_available
                if(this.quantity_pwp_new[variant.id] < 0) {
                    this.quantity_pwp_new[variant.id] = 0
                }
            }
        },

        updateQuantity() {
            this.quantity_pwp_updated = true
            this.$router.go(-1)
        }
    },

    created() {
        this.quantity_pwp_new = { ...this.quantity_pwp }
    },

    mounted() {
        $('.owl-carousel')
            .on("initialized.owl.carousel changed.owl.carousel", function(e) {
                if (!e.namespace) {
                    return;
                }
                $("#counter").text(
                    e.relatedTarget.relative(e.item.index) + 1 + "/" + e.item.count
                );
            })
            .owlCarousel({
                margin:10,
                nav:false,
                responsive:{
                    0:{
                        items:1
                    },
                }
            });
    },

    beforeRouteEnter (to, from, next) {
        if(to.params.item == undefined) {
            next({name: 'main-menu', replace: true})
        }
        else {
            next()
        }
    },

    beforeRouteLeave(to, from, next) {
        clearInterval(this.interval_cd);
        to.params['pwp_details'] = this.pwp_details
        to.params['order_id'] = this.order_id
        to.params['quantity_pwp'] = this.quantity_pwp_updated ? this.quantity_pwp_new : this.quantity_pwp

        next()
    }
}
</script>

<style lang="scss" scoped>
    .image-slider {
        position: relative;
        height: 30rem;
        max-height: 100vw;

        #counter {
            position: absolute;
            right: 1rem;
            top: 1rem;
            box-shadow: 2px 2px 4px 0px #9c9c9c;
            padding: 0 1rem;
            border-radius: 0.2rem;
            z-index: 1;
            background: #ffffff;
            font-size: 0.75rem;
        }
    }

    .product-image {
        height: calc(100vw - 0.6rem);
        width: calc(100vw - 0.6rem);
        max-height: 30rem;
        margin: 0.3rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        position: relative;
    }

    .total-sold {
        color: #787373;
    }

    .variant {
        color: #787373;
    }

    .original-price {
        color: #787373;
    }

    .current-price {
        color: #ed7f35;
        font-weight: bold;
        font-size: 1rem;
    }

    .input-group {
        input {
            height: auto;
            background-color: #ffffff;

            &.disabled {
                background-color: #c8c8c8;
            }
        }
        .input-group-text {
            background-color: #ffffff;

            &.disabled {
                background-color: #a6a6a6;
                color: #ffffff !important;
            }
        }
    }
</style>
