<template>
    <div class="gomart">
        <secondary-header title="GoMart" backRoute="default">
            <template v-slot:menu>
                <div class="main-menu text-only">
                    <ul class="nav nav-pills nav-fill container">
                        <li class="nav-item">
                            <a class="nav-item font-weight-bold">
                                <div class="text-dark">
                                    <span> {{ lang('My Cart') }}</span>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </template>
        </secondary-header>

        <main>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <div class="pb-3">
                            <div class="text-right text-danger font-weight-bold float-right mt-2">
                                {{ timer }}
                            </div>
                            <div class="font-weight-bold py-2">
                                {{ lang('Select Delivery Method') }}
                            </div>
                            <div class="">
                                <div class="float-right">
                                    {{ lang('Credit Balance') }}: <b>{{ money(wallet_details.wallet_balance) }}</b>
                                </div>
                                <div class="custom-control custom-radio large center py-1">
                                    <input type="radio" id="self_collect" name="delivery_method" value="SELF_COLLECT" class="custom-control-input" v-model="delivery_details.delivery_method">
                                    <label class="custom-control-label" for="self_collect">{{ lang('Self Collect') }}</label>
                                </div>
                                <div v-if="delivery_details.delivery_method == 'SELF_COLLECT'" class="pl-4 pb-2">
                                    <div class="form-group">
                                        <label for="self_collect_date" class="font-weight-bold">{{ lang('Self Collect Date') }}:</label>
                                        <input type="date" class="form-control" id="self_collect_date" v-model="delivery_details.self_collect_date" :min="currentDate">
                                    </div>
                                    <div class="form-group">
                                        <label for="self_collect_time" class="font-weight-bold">{{ lang('Self Collect Time') }}:</label>
                                        <select class="form-control" name="self_collect_time" v-model="delivery_details.self_collect_time">
                                            <option value=""></option>
                                            <option v-for="(time, actual_time) in time_selections" :value="time" :disabled="isDisableTime[actual_time]">{{ time }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="custom-control custom-radio large py-1">
                                    <input type="radio" id="delivery" name="delivery_method" value="DELIVERY" class="custom-control-input" v-model="delivery_details.delivery_method">
                                    <label class="custom-control-label" for="delivery">{{ lang('Delivery') }}</label>
                                </div>
                                <div v-if="delivery_details.delivery_method == 'DELIVERY'" class="pl-4 pb-2">
                                    <AddAddressModal v-on:address-created="newAddressCreated" v-on:address-modal-closed="clearDefaultValue" :default_value="customer_address">
                                        <button type="button" class="btn btn-secondary btn-sm float-right" data-toggle="modal" data-target="#modal-new-address">+ {{ lang('Address') }}</button>
                                    </AddAddressModal>

                                    <div class="font-weight-bold mb-2">{{ lang('My Address') }}</div>

                                    <div v-for="address in customer_addresses" class="custom-control custom-radio address py-1">
                                        <input type="radio" :id="'address_'+ address.id" name="address_id" :value="address.id" class="custom-control-input" v-model="delivery_details.address_id" :disabled="!address.state || !address.city">
                                        <label class="custom-control-label" :for="'address_'+ address.id" style="white-space:pre-line;">
                                            <strong>{{ address.recipient_name }}</strong><br>
                                            {{ address.phone_no }}<br>
                                            {{ address.address_text }}<br>
                                            {{ address.postcode }} {{ address.city }}<span v-if="address.city && address.state_id">,</span> {{ address.state }}
                                        </label>
                                        <a href="#" data-toggle="modal" data-target="#modal-new-address" class="edit-address large mt-2" @click.prevent="editAddress(address)"><font-awesome-icon :icon="['far','edit']" size="lg"></font-awesome-icon></a>
                                        <div v-if="!address.state || !address.city" class="please-update">
                                            {{ lang('please update') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="remove-x-margin">
                            <div class="font-weight-bold border-top border-bottom p-3 large bg-yellow">
                                {{ products[0].branch_name }}
                            </div>

                            <table width="100%" class="mb-2">
                                <tr v-for="product in products">
                                    <td width="30%" class="pl-3 p-2 text-center border-bottom" style="vertical-align: baseline;">
                                        <div class="ref-no small">
                                            {{ product.product_reference_no }}
                                        </div>
                                        <div class="image">
                                            <img :src="product.product_image_url[0]" alt="">
                                        </div>
                                    </td>
                                    <td width="60%" class="p-2 pr-3 border-bottom" style="vertical-align: top;">
                                        <div class="product-name">
                                            {{ product.product_name }}
                                        </div>
                                        <div class="details">
                                            <table>
                                                <tr v-for="variant in product.variants" v-if="quantity[variant.id]">
                                                    <td class="pr-3">{{ variant.name }}</td>
                                                    <td>
                                                        {{ 'RM '+ variant.rate }}</span> <span class="avoidwrap">{{ ' x '+ quantity[variant.id].quantity }}</span>
                                                    </td>
                                                    <td>=</td>
                                                    <td>
                                                        {{ 'RM '+ (variant.rate_raw*quantity[variant.id].quantity).toFixed(2) }}
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </td>
                                </tr>
                            </table>

                            <div class="px-3">
                                <table width="100%" class="large">
                                    <tr>
                                        <td>{{ 'Subtotal' }}</td>
                                        <td>:</td>
                                        <td class="text-right">{{ money(sub_total) }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ 'Delivery Fee' }}</td>
                                        <td>:</td>
                                        <td v-if="delivery_details.delivery_method == 'SELF_COLLECT' || delivery_fee !== null" class="text-right">{{ money(delivery_fee) }}</td>
                                        <td v-else class="text-right">RM ?</td>
                                    </tr>
                                    <tr>
                                        <td class="font-weight-bold">{{ 'Total' }}</td>
                                        <td>:</td>
                                        <td v-if="delivery_details.delivery_method == 'SELF_COLLECT' || delivery_fee !== null" class="text-right font-weight-bold text-danger">{{ money(total_amount) }}</td>
                                        <td v-else class="text-right font-weight-bold text-danger">RM ?</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <float-bottom>
                            <div v-if="wallet_details.wallet_balance < total_amount && !this.state.multi_loading.wallet_details">
                                <a href="#" class="btn" @click.prevent="closeApp()" style="background-color: #ed7d31;">
                                    <div class="text-center">
                                        {{ lang('Reload') }}
                                    </div>
                                </a>
                            </div>
                            <div v-else-if="!wallet_details.has_pin && !this.state.multi_loading.wallet_details">
                                <a href="#" class="btn" @click.prevent="closeApp()" style="background-color: #ed7d31;">
                                    <div class="text-center">
                                        {{ lang('Set Transaction Pin') }}
                                    </div>
                                </a>
                            </div>
                            <div v-else-if="delivery_details.delivery_method == 'SELF_COLLECT' || delivery_fee !== null" class="">
                                <router-link :to="{name: 'product.checkout.proceed', params: {products: products, quantity: quantity, delivery_details: delivery_details} }" class="btn btn-primary" :class="{disabled : !can_checkout}">
                                    <div class="text-center">
                                        {{ lang('Pay Now Total:') }} {{ money(total_amount) }}
                                    </div>
                                </router-link>
                            </div>
                            <div v-else-if="delivery_details.delivery_method == 'DELIVERY'" class="">
                                <a href="#" @click.prevent="getQuotation" class="btn btn-primary" :class="{disabled : !can_checkout_delivery}">
                                    <div class="text-center">
                                        {{ lang('Get Quote') }}
                                    </div>
                                </a>
                            </div>
                            <div v-else>
                                <a class="btn btn-primary disabled">
                                    <div class="text-center">
                                        {{ lang('Select Delivery Method') }}
                                    </div>
                                </a>
                            </div>
                        </float-bottom>
                    </div>
                </div>
            </div>
        </main>

        <loader :loading="state.multi_loading"></loader>
        <main-footer></main-footer>
    </div>
</template>

<script>
import AddAddressModal from '@/components/AddAddressModal.vue'
export default {
    props: ['fudc', 'products', 'quantity', 'product_branch', 'special_price_is_active_period'],

    components: {AddAddressModal},

    data() {
        return {
            delivery_details: {
                delivery_method: '',
                self_collect_date: '',
                self_collect_time: '',
                address_id: '',
            },
            time_selections: {
                '08:00' : '08:00 - 08:59',
                '09:00' : '09:00 - 09:59',
                '10:00' : '10:00 - 10:59',
                '11:00' : '11:00 - 11:59',
                '12:00' : '12:00 - 12:59',
                '13:00' : '13:00 - 13:59',
                '14:00' : '14:00 - 14:59',
                '15:00' : '15:00 - 15:59',
                '16:00' : '16:00 - 16:59',
            },
            customer_addresses: [],
            default_delivery_fees: [],
            change_delivery_method: false,
            customer_address: '',

            lock_seconds: 300,
            timer: '',
            interval_cd: '',
            locked_ids: [],

            wallet_details: {},
        }
    },

    watch: {
        'delivery_details.self_collect_date': function(newDate) {
            if(!this.change_delivery_method) {
                this.delivery_details.self_collect_time = ''
            }
            this.change_delivery_method = false
        },

        'delivery_details.delivery_method': function(newVal) {
            this.change_delivery_method = true
            if(newVal == 'SELF_COLLECT') {
                this.delivery_details.self_collect_date = this.currentDate
                this.delivery_details.self_collect_time = this.time_selections[this.currentTime.format('HH:mm')]
                if(moment().isAfter(moment('16:00', 'HH:mm'))) {
                    this.delivery_details.self_collect_date = moment().add(1, 'days').format('yyyy-MM-DD')
                }
                if(!this.delivery_details.self_collect_time) {
                    this.delivery_details.self_collect_time = this.time_selections['08:00']
                }
                this.delivery_details.address_id = ''
            }
            else if(newVal == 'DELIVERY') {
                this.delivery_details.self_collect_date = ''
                this.delivery_details.self_collect_time = ''
            }
        }
    },

    computed: {
        currentDate: function() {
            return moment().format('yyyy-MM-DD')
        },

        currentTime: function() {
            return moment().startOf('hour')
        },

        isDisableTime: function() {
            return {
                '08:00' : this.delivery_details.self_collect_date == this.currentDate ? moment('08:00', 'HH:mm').isBefore(this.currentTime) : false,
                '09:00' : this.delivery_details.self_collect_date == this.currentDate ? moment('09:00', 'HH:mm').isBefore(this.currentTime) : false,
                '10:00' : this.delivery_details.self_collect_date == this.currentDate ? moment('10:00', 'HH:mm').isBefore(this.currentTime) : false,
                '11:00' : this.delivery_details.self_collect_date == this.currentDate ? moment('11:00', 'HH:mm').isBefore(this.currentTime) : false,
                '12:00' : this.delivery_details.self_collect_date == this.currentDate ? moment('12:00', 'HH:mm').isBefore(this.currentTime) : false,
                '13:00' : this.delivery_details.self_collect_date == this.currentDate ? moment('13:00', 'HH:mm').isBefore(this.currentTime) : false,
                '14:00' : this.delivery_details.self_collect_date == this.currentDate ? moment('14:00', 'HH:mm').isBefore(this.currentTime) : false,
                '15:00' : this.delivery_details.self_collect_date == this.currentDate ? moment('15:00', 'HH:mm').isBefore(this.currentTime) : false,
                '16:00' : this.delivery_details.self_collect_date == this.currentDate ? moment('16:00', 'HH:mm').isBefore(this.currentTime) : false,
            }
        },

        sub_total: function() {
            var total = 0
            for (var key in this.quantity) {
                if (this.quantity.hasOwnProperty(key)) {
                    this.products.forEach((product, i) => {
                        var variant = product.variants.find(variant => variant.id == key)
                        if(variant) {
                            total += this.quantity[key].quantity * variant.rate_raw
                        }
                    });
                }
            }
            return total
        },

        delivery_fee: function() {
            if(this.default_delivery_fee) {
                if(this.sub_total <= this.default_delivery_fee.price_and_below) {
                    return this.default_delivery_fee.price_and_below_fee
                }
                else if(this.sub_total >= this.default_delivery_fee.price_from && this.sub_total <= this.default_delivery_fee.price_up_to) {
                    return this.default_delivery_fee.price_up_to_fee
                }
                else if(this.sub_total >= this.default_delivery_fee.price_and_above) {
                    return this.default_delivery_fee.price_and_above_fee
                }
            }
            return null
        },

        total_amount:function() {
            return this.sub_total + (this.delivery_fee !== null ? this.delivery_fee : 0)
        },

        can_checkout: function() {
            return this.total_amount > 0
                && (
                    (
                        this.delivery_details.delivery_method == 'SELF_COLLECT'
                        && this.delivery_details.self_collect_date
                        && this.delivery_details.self_collect_time
                    )
                    || (
                        this.delivery_details.delivery_method == 'DELIVERY'
                        && this.delivery_fee !== null
                        && this.delivery_fee >= 0
                        && this.delivery_details.address_id
                    )
                )
        },

        can_checkout_delivery: function() {
            return this.total_amount > 0
                && this.delivery_details.delivery_method == 'DELIVERY'
                && this.delivery_details.address_id
        },

        default_delivery_fee: function() {
            var addr = _.find(this.customer_addresses, {id: this.delivery_details.address_id})
            var delivery_fee = null;
            if(addr) {
                delivery_fee = _.find(this.default_delivery_fees, {state_id: addr.state_id, city_id: addr.city_id})
            }
            return delivery_fee
        }
    },

    methods: {
        countdown() {
            this.interval_cd = setInterval(() => {
                this.lock_seconds = this.lock_seconds - 1;
                this.timer = moment.utc(this.lock_seconds*1000).format('mm:ss')

                if (this.lock_seconds <= 0) {
                    Swal.fire({
                        text: this.lang('Reserved time end'),
                        timer: 3000
                    })
                    clearInterval(this.interval_cd);
                    this.$router.go(-1)
                }
            }, 1000)
        },

        loadCustomerAddresses() {
            this.$set(this.state.multi_loading, 'customer_address', true)
            axios.get(this.GLOBAL.API_BASE_URL +"customer-address")
                .then(response => {
                    this.customer_addresses = response.data
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state.multi_loading, 'customer_address', false))
        },

        newAddressCreated() {
            this.loadCustomerAddresses()
        },

        editAddress(address) {
            this.customer_address = address
        },

        clearDefaultValue() {
            this.customer_address = ''
        },

        loadDeliveryFee() {
            this.$set(this.state.multi_loading, 'delivery_fee', true)
            axios.get(this.GLOBAL.API_BASE_URL +"delivery-fee/"+ this.products[0].branch_ref_no)
                .then(response => {
                    this.default_delivery_fees = response.data
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state.multi_loading, 'delivery_fee', false))
        },

        getQuotation() {
            fireLoader('Requesting...')
            this.$set(this.state.multi_loading, 'quotation', true)
            axios.post(this.GLOBAL.API_BASE_URL +"request-quotation", {
                    products: this.products,
                    quantity: this.quantity,
                    delivery_details: this.delivery_details
                })
                .then(response => {
                    Swal.fire({
                        type: 'error',
                        text: this.lang('Your request has been send Successfully!'),
                    })
                    this.$router.replace({name:'main-menu'})
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state.multi_loading, 'quotation', false))
        },

        lockVariant() {
            axios.post(this.GLOBAL.API_BASE_URL +"product-variant-lock", {quantity: this.quantity})
                .then(response => {
                    this.locked_ids = response.data.locked_ids
                })
                .catch(error=>{
                    if(error.response.status == 422) {
                        if(error.response.data.code == "purchase_limit") {
                            Swal.fire({
                                text: this.lang('Sorry, you have reached the purchase limit.'),
                                confirmButtonText: this.lang('Close'),
                                onClose: () => {
                                    this.quantity = {}
                                    this.$router.go(-1)
                                }
                            })
                        } else {
                            Swal.fire({
                                title: error.response.data == "purchase_limit" ? this.lang('Sorry, you have reached the purchase limit') : this.lang('Item Sold Out !'),
                                text: this.lang('Please contact merchant if you wish to make order.'),
                                confirmButtonText: this.lang('Close'),
                                onClose: () => {
                                    this.quantity = {}
                                    this.$router.go(-1)
                                }
                            })
                        }
                    } else {
                        defaultErrorManagement(error.response)
                    }
                })
        },

        loadCustomerWalletDetails() {
            this.$set(this.state.multi_loading, 'wallet_details', true)
            axios.get(this.GLOBAL.API_BASE_URL +"customer/wallet-details")
                .then(response => {
                    this.wallet_details = response.data
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state.multi_loading, 'wallet_details', false))
        },

        closeApp() {
            // close browser on native app
            window.ReactNativeWebView.postMessage("WINDOW_CLOSED")
        },

        async unlockVariant() {
            await axios.post(this.GLOBAL.API_BASE_URL +"product-variant-unlock", {locked_ids: this.locked_ids})
                .then(response => {
                    console.log(response.data)
                })
                .catch(error=> {
                    defaultErrorManagement(error.response)
                })
        },
    },

    created() {
        this.countdown()
        this.loadCustomerAddresses()
        this.lockVariant()
        this.loadDeliveryFee()
        this.loadCustomerWalletDetails()
    },

    mounted() {
        $('body').addClass('remove-header-menu')
    },

    destroyed () {
        $('body').removeClass('remove-header-menu')
    },

    beforeRouteEnter (to, from, next) {
        if(to.params.products == undefined) {
            if(to.params.order_id) {
                next({name: 'my-order.view', params:{order_id: to.params.order_id}, replace: true})
            } else {
                next({name: 'main-menu', replace: true})
            }
        }
        else {
            next()
        }
    },

    beforeRouteLeave(to, from, next) {
        $('#modal-new-address').modal('hide')
        to.params['fudc'] = this.fudc
        to.params['products'] = this.products
        to.params['prev_quantity'] = this.quantity

        clearInterval(this.interval_cd);

        if(this.locked_ids.length > 0 && to.name != 'product.checkout.proceed') {
            this.unlockVariant().then(() => next())
        } else {
            next()
        }
    }
}
</script>

<style lang="scss" scoped>
    .custom-radio {
        &.center {
            label {
                display: flex;
                align-items: center;

                &::before,
                &::after {
                    top: unset
                }
            }
        }

        &.address {
            position: relative;

            .custom-control-input:checked ~ label::before {
                border-color: #d4c18a;
                background-color: #dccc9a;
            }

            label {
                padding-right: 1.7rem;
            }

            .edit-address {
                position: absolute;
                right: 0;
                top: 0.3rem;
            }
        }
    }

    .please-update {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 1.5rem;
        left: 1.4rem;
        background-color: #ffffffad;
        text-align: center;
        color: #ff2e2e;
        padding: 1rem 0;
    }
</style>
