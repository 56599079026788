<template>
    <div class="gomart main-page">
        <secondary-header title="GoMart" backRoute="close_app">
            <template v-slot:menu>
                <div class="main-menu text-only">
                    <ul class="nav nav-pills nav-fill container">
                        <li class="nav-item">
                            <router-link :to="{name: 'my-order'}" class="nav-item">
                                <div class="text-dark">
                                    <img src="@/assets/action_icon/grey/MY_ORDER.png" alt="" height="25">
                                    <span> {{ lang('My Order') }}</span>
                                </div>
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{name: 'history'}" class="nav-item">
                                <div class="text-dark">
                                    <img src="@/assets/action_icon/grey/HISTORY.png" alt="" height="25">
                                    <span> {{ lang('My History') }}</span>
                                </div>
                            </router-link>
                        </li>
                    </ul>
                </div>
            </template>
            <template v-slot:right>
                <div class="home-button">
                    <router-link :to="{name:'user-bar'}" class="nav-item">
                        <font-awesome-icon icon="bars" size="lg"></font-awesome-icon>
                    </router-link>
                </div>
            </template>
        </secondary-header>

        <main>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <div class="row fudc py-1 px-3">
                            <form @submit.prevent="search()" style="width:100%" class="mb-2 mt-1">
                                <input
                                    name="keyword"
                                    class="form-control form-control-lg keyword-search-ignore"
                                    v-model="keyword"
                                    :placeholder="lang('Search')"
                                    autocomplete="off"
                                    @input="keyedIn"
                            		@change="keyedIn"
                            		@cut="keyedIn"
                            		@copy="keyedIn"
                            		@paste="keyedIn"
                            		@keyup="keyedIn"
                                />
                                <div v-if="show_keyword_options && keyword_options.length > 0" class="keyword-options">
                                    <div v-for="opt in keyword_options" class="item" @click="selectKeyword(opt)">{{ opt }}</div>
                                </div>
                                <button type="submit" name="button" hidden></button>
                            </form>

                            <div v-for="fudc in fudc_sorteds" v-if="fudc.is_show_in_app" class="col-4 text-center">
                                <img :src="fudc.icon_url" alt="" @click="selectFudc(fudc)">
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </main>

        <main-footer></main-footer>
        <loader v-if="state.loading"></loader>
    </div>
</template>

<script>
import _ from 'lodash'

export default {
    data() {
        return {
            keyword: '',
            keyword_options: [],
            show_keyword_options: false,

            fudcs: [],
        }
    },

    computed: {
        fudc_sorteds: function() {
            return _.sortBy(this.fudcs, ['in_app_index', 'name'])
        }
    },

    methods: {
        getFudc() {
            this.$set(this.state, 'loading', true)
            if(sessionStorage.fudcs) {
                this.fudcs = JSON.parse(sessionStorage.fudcs)
                this.$set(this.state, 'loading', false)
            } else {
                axios.get(this.GLOBAL.API_BASE_URL +"fudc/list")
                    .then(response => {
                        this.fudcs = response.data
                        sessionStorage.fudcs = JSON.stringify(this.fudcs)
                    })
                    .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                    .finally(() => this.$set(this.state, 'loading', false))
            }
        },

        selectFudc(fudc) {
            this.$router.push({ name: 'product.menu', params: {fudc: fudc} })
        },

        search() {
            this.$router.push({ name: 'product.menu', params: {fudc: {name:this.lang('All'),code:null,id:null}, keyword: this.keyword} })
        },

        getKeywordOptions() {
            if(this.keyword) {
                axios.post(this.GLOBAL.API_BASE_URL +"product/keyword-options", {
                        keyword: this.keyword
                    })
                    .then(response => {
                        this.keyword_options = response.data
                    })
                    .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                    .finally(() => this.$set(this.state, 'loading', false))
            } else {
                this.keyword_options = []
            }
        },

        selectKeyword(keyword) {
            this.keyword = keyword
            $('input[name="keyword"]').focus()
            this.show_keyword_options = false
            this.debounceGetKeywordOptions()
        },

        keyedIn(event) {
            this.keyword = event.target.value
            this.debounceGetKeywordOptions()
        }
    },

    created() {
        this.getFudc()
        this.debounceGetKeywordOptions = _.debounce(this.getKeywordOptions, 200)
    },

    mounted() {
        $('input[name="keyword"]').on('click', () => {
            this.show_keyword_options = true
        })

        $('html').on('click', (e) => {
            if($(e.target).hasClass('keyword-search-ignore')) {
                this.show_keyword_options = true
            } else {
                this.show_keyword_options = false
            }
        })
    },

    beforeRouteLeave(to, from, next) {
        if(to.name == 'auth') {
            window.ReactNativeWebView.postMessage("WINDOW_CLOSED")
        } else {
            next()
        }
    }
}
</script>

<style lang="scss" scoped>
    .nav-item:not(:last-child) {
        border-right: 1px solid #e3e3e3;
    }

    .summary {
        font-size: 0.9rem;
    }

    .fudc {
        form {
            position: relative;
        }

        .keyword-options {
            position: absolute;
            z-index: 5;
            width: 100%;
            background-color: white;
            border-radius: 5px;
            box-shadow: 0px 2px 4px 1px #ada9a9;
            overflow: hidden;

            .item {
                cursor: pointer;
                padding: 0.3rem 0.8rem;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;

                &:hover {
                    background-color: #def1f9;
                }
            }
        }
    }
</style>
