<template>
    <div v-if="is_loading" class="loader">
        <div class="m-auto">
            <i class="fas fa-spinner fa-spin"></i><font-awesome-icon icon="spinner" class="fa-spin" size="3x"></font-awesome-icon>
        </div>
    </div>
</template>

<script>
import Rating from '@/components/Rating.vue'
export default {
    props: ['loading'],

    computed: {
        is_loading: function() {
            var is_load = true
            if(this.loading !== null && typeof this.loading === 'object') {
                is_load = false
                for (const loader in this.loading) {
                    is_load = is_load || this.loading[loader]
                }
            }
            return is_load
        }
    }
}
</script>

<style scoped lang="scss">
.loader {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    background-color: #ffffffbf;
}
</style>
