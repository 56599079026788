<template>
    <div class="gomart">
        <secondary-header title="GoMart" backRoute="default">
            <template v-slot:menu>
                <div class="main-menu text-only">
                    <ul class="nav nav-pills nav-fill container">
                        <li class="nav-item">
                            <a class="nav-item font-weight-bold">
                                <div class="text-dark">
                                    <span> {{ lang('My Favourite') }}</span>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </template>
            <template v-slot:right>
                <div class="home-button">
                    <router-link :to="{name:'user-bar'}" class="nav-item">
                        <font-awesome-icon icon="bars" size="lg"></font-awesome-icon>
                    </router-link>
                </div>
            </template>
        </secondary-header>

        <main>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <div class="product-menu remove-x-margin">
                            <table class="product-list" width="100%">
                                <template v-for="product in product_list">
                                    <tr :key="'product-list1-'+product.id">
                                        <td rowspan="2" class="left">
                                            <div class="ref-no small">
                                                {{ product.product_reference_no }}
                                            </div>
                                            <div class="image">
                                                <router-link :to="{ name:'product.add-to-cart', params: {product: product, product_branch: {branch_ref_no: product.branch_ref_no}, fudc: {name: lang('All'),code:null,id:null}} }">
                                                    <img :src="product.product_image_thumbnail_url[0]" alt="">
                                                </router-link>
                                            </div>
                                            <div class="view small my-1">
                                                <img src="@/assets/action_icon/VIEW.png" alt="" width="21"> {{ product.total_view }}
                                            </div>
                                        </td>
                                        <td class="right">
                                            <div class="product-name">
                                                {{ product.product_name }}
                                            </div>
                                            <div class="details">
                                                <span class="float-right font-weight-bold"><span class="text-danger">{{ product.price_variant }}</span>/{{ product.unit_variant }}</span>
                                                {{ lang('Transacted') }}: {{ product.total_transacted }} <br>
                                                {{ lang('Availability') }}: <span v-if="product.total_available > 0">{{ product.total_available }}</span> <span v-else class="text-warning">{{ lang('Sold Out') }}</span> <br>
                                                {{ product.city }}, {{ product.state }} <br>
                                                {{ product.description }}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr :key="'product-list2-'+product.id">
                                        <td class="right-last">
                                            <div class="row no-gutters">
                                                <div class="navigation col-7">
                                                    <div>
                                                        <a :href="product.tnc_link" class="text-confirm">T&C</a> | <a :href="'https://maps.google.com/maps?daddr='+ product.latitude +','+ product.longitude" class="text-confirm">Location</a>
                                                        <div class="mt-1 navigation-icons">
                                                            <img src="@/assets/action_icon/CHAT.png" alt="" class="icon">
                                                            <favourite-icon :product="product" />
                                                            <img src="@/assets/action_icon/MERCHANT.png" alt="" class="icon" @click="$parent.viewBranch({branch_ref_no: product.branch_ref_no })">
                                                            <img src="@/assets/action_icon/SHARE.png" alt="" class="icon">
                                                            <img v-if="product.is_halal" src="@/assets/action_icon/HALAL.png" alt="" class="icon">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-5">
                                                    <router-link :to="{ name:'product.add-to-cart', params: {product: product, product_branch: {branch_ref_no: product.branch_ref_no}, fudc: {name: lang('All'),code:null,id:null}} }" class="btn btn-primary btn-sm btn-block text-white">{{ lang('Add to cart') }}</router-link>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                                <tr v-if="this.state.loading_next">
                                    <td colspan="2" class="text-center p-3">{{ lang('loading') }}</td>
                                </tr>
                                <tr v-if="!this.state.loading && this.products.meta.total == 0">
                                    <td colspan="2" class="text-center p-3 font-weight-bold text-center" style="font-size: 1rem;">{{ lang('No item set as favourite') }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </main>

        <loader v-if="state.loading"></loader>
        <main-footer></main-footer>
    </div>
</template>

<script>
export default {
    data() {
        return {
            products: {
                data: [],
                meta: {},
            },
        }
    },

    computed: {
        product_list: function() {
            return _.filter(this.products.data, 'is_liked')
        }
    },

    methods: {
        loadProduct() {
            this.$set(this.state, 'loading', true)
            axios.get(this.GLOBAL.API_BASE_URL +"customer-favourite")
                .then(response => {
                    this.products.data = response.data.data
                    this.products.next_page = response.data.links.next
                    this.products.meta = response.data.meta
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading', false))
        },

        loadProductNextPage() {
            let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight + 1 >= document.documentElement.offsetHeight
            if(this.products.next_page && bottomOfWindow) {
                this.$set(this.state, 'loading_next', true)
                axios.get(this.GLOBAL.API_BASE_URL +"customer-favourite?page="+ this.products.meta.next_page)
                    .then(response => {
                        this.products.data.push.apply(this.products.data, response.data.data)
                        this.products.next_page = response.data.links.next
                        this.products.meta = response.data.meta
                        this.loadProductNextPage()
                    })
                    .catch(error=>defaultErrorManagement(error.response))
                    .finally(() => this.$set(this.state, 'loading_next', false))

                this.products.next_page = ''
            }
        },
    },

    created() {
        if(this.products.data.length <= 0) {
            this.loadProduct()
        }

        window.addEventListener('scroll', this.loadProductNextPage);
    },

    destroyed () {
        window.removeEventListener('scroll', this.loadProductNextPage);
    },
}
</script>
