<template>
    <div class="gomart single-header">
        <secondary-header title="Payment Status"></secondary-header>

        <main>
            <div class="container select-bills">
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <div class="content pt-4">

                            <!-- <label class="font-weight-bold mb-0">{{ lang('Pay with') }} <img src="@/assets/images/fpx/fpx-logo.png" alt="FPX" height="25"></label><br> -->

                            <div v-if="payment_status.transaction_return_post" class="text-center mt-5">
                                <div v-if="payment_status.status == 'success'">
                                    <h5 class="text-success"><b>{{ lang('Payment Successful') }}</b></h5>
                                    <h3><b>{{ money(payment_status.amount) }}</b></h3>
                                </div>
                                <div v-else-if="payment_status.status == 'fail'">
                                    <h5 class="text-danger"><b>{{ lang('Payment Unsuccessful') }}</b></h5>
                                </div>
                            </div>
                            <div v-if="payment_status.transaction_return_post" class="my-5">
                                <table width="100%">
                                    <tr>
                                        <td>{{ lang('Date & Time') }}</td>
                                        <td>&nbsp;:&nbsp;</td>
                                        <td>{{ payment_status.transaction_date }}</td>
                                    </tr>
                                    <tr>
                                        <td>{{ lang('Transaction Amount') }}</td>
                                        <td>&nbsp;:&nbsp;</td>
                                        <td><b>{{ money(payment_status.amount) }}</b></td>
                                    </tr>
                                </table>
                            </div>

                            <router-link :to="{name: 'main-menu'}">
                                <button type="button" class="btn btn-primary btn-block">Home</button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <loader v-if="state.loading"></loader>
    </div>
</template>

<script>

export default {
    props: ['transaction_id'],

    data() {
        return {
            payment_status: {}
        }
    },

    methods: {
        getPaymentStatus() {
            this.$set(this.state, 'loading', true)
            axios.post(this.GLOBAL.API_BASE_URL + "payment-status", {transaction_id: this.transaction_id})
                .then(response => {
                    this.payment_status = response.data
                    if(response.data == '') {
                        this.$router.replace({ name: 'main-menu' })
                    }
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading', false))
        }
    },

    created() {
        this.getPaymentStatus()
    }
}
</script>
