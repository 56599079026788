<template>
    <div class="">
        <img v-if="product.is_liked" src="@/assets/action_icon/MY_FAVORITE.png" alt="" class="icon" @click="toggle">
        <img v-else src="@/assets/action_icon/FAVORITE.png" alt="" class="icon" @click="toggle">
    </div>
</template>

<script>
export default {
    props: ['product'],

    methods: {
        toggle() {
            this.$set(this.state, 'loading', true)
            axios.put(this.GLOBAL.API_BASE_URL +"customer-favourite/product/"+ this.product.id +"/toggle")
                .then(response => {
                    this.product.is_liked = response.data.status
                })
                .catch(error=>defaultErrorManagement(error.response)) //to catch the errors if any
                .finally(() => this.$set(this.state, 'loading', false))
        }
    }
}
</script>
